import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Alert,
  CircularProgress,
  Divider,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {
  Store as StoreIcon,
  Payment as PaymentIcon,
  Notifications as NotificationsIcon,
  Language as LanguageIcon,
  ExpandMore as ExpandMoreIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import api from '../../utils/api';

const Settings = () => {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setLoading(true);
      const { data } = await api.get('/settings');
      setSettings(data);
    } catch (err) {
      setError('Failed to load settings');
      console.error('Error loading settings:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleStoreSettingsSubmit = async (e) => {
    e.preventDefault();
    try {
      setSaving(true);
      const { data } = await api.put('/settings/store', settings.store);
      setSettings(data);
      setSuccess('Store settings updated successfully');
    } catch (err) {
      setError('Failed to update store settings');
    } finally {
      setSaving(false);
    }
  };

  const handlePaymentSettingsSubmit = async (e) => {
    e.preventDefault();
    try {
      setSaving(true);
      const { data } = await api.put('/settings/payment', settings.payment);
      setSettings(data);
      setSuccess('Payment settings updated successfully');
    } catch (err) {
      setError('Failed to update payment settings');
    } finally {
      setSaving(false);
    }
  };

  const handleNotificationSettingsSubmit = async (e) => {
    e.preventDefault();
    try {
      setSaving(true);
      const { data } = await api.put('/settings/notification', settings.notification);
      setSettings(data);
      setSuccess('Notification settings updated successfully');
    } catch (err) {
      setError('Failed to update notification settings');
    } finally {
      setSaving(false);
    }
  };

  const handleRegionalSettingsSubmit = async (e) => {
    e.preventDefault();
    try {
      setSaving(true);
      const { data } = await api.put('/settings/regional', settings.regional);
      setSettings(data);
      setSuccess('Regional settings updated successfully');
    } catch (err) {
      setError('Failed to update regional settings');
    } finally {
      setSaving(false);
    }
  };

  const handleInputChange = (section, field, value) => {
    setSettings(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  const handleNestedInputChange = (section, parent, field, value) => {
    setSettings(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [parent]: {
          ...prev[section][parent],
          [field]: value
        }
      }
    }));
  };

  if (loading) return <CircularProgress />;
  if (!settings) return <Alert severity="error">Failed to load settings</Alert>;

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Store Settings
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Store Settings */}
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <StoreIcon />
                <Typography variant="h6">Store Information</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <form onSubmit={handleStoreSettingsSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Store Name"
                      value={settings.store.name}
                      onChange={(e) => handleInputChange('store', 'name', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Store Email"
                      value={settings.store.email}
                      onChange={(e) => handleInputChange('store', 'email', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Phone"
                      value={settings.store.phone}
                      onChange={(e) => handleInputChange('store', 'phone', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      label="Store Description"
                      value={settings.store.description}
                      onChange={(e) => handleInputChange('store', 'description', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Address
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Street"
                          value={settings.store.address?.street || ''}
                          onChange={(e) => handleNestedInputChange('store', 'address', 'street', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="City"
                          value={settings.store.address?.city || ''}
                          onChange={(e) => handleNestedInputChange('store', 'address', 'city', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="State"
                          value={settings.store.address?.state || ''}
                          onChange={(e) => handleNestedInputChange('store', 'address', 'state', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Country"
                          value={settings.store.address?.country || ''}
                          onChange={(e) => handleNestedInputChange('store', 'address', 'country', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Zip Code"
                          value={settings.store.address?.zipCode || ''}
                          onChange={(e) => handleNestedInputChange('store', 'address', 'zipCode', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={saving}
                      startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
                    >
                      Save Store Settings
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Payment Settings */}
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PaymentIcon />
                <Typography variant="h6">Payment Settings</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <form onSubmit={handlePaymentSettingsSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Currency"
                      value={settings.payment.currency}
                      onChange={(e) => handleInputChange('payment', 'currency', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Shipping Fee"
                      value={settings.payment.shippingFee}
                      onChange={(e) => handleInputChange('payment', 'shippingFee', Number(e.target.value))}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Tax Rate (%)"
                      value={settings.payment.taxRate}
                      onChange={(e) => handleInputChange('payment', 'taxRate', Number(e.target.value))}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Minimum Order Amount"
                      value={settings.payment.minimumOrderAmount}
                      onChange={(e) => handleInputChange('payment', 'minimumOrderAmount', Number(e.target.value))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Payment Methods
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={settings.payment.paymentMethods.paystack}
                          onChange={(e) => handleNestedInputChange('payment', 'paymentMethods', 'paystack', e.target.checked)}
                        />
                      }
                      label="Paystack"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={settings.payment.paymentMethods.cashOnDelivery}
                          onChange={(e) => handleNestedInputChange('payment', 'paymentMethods', 'cashOnDelivery', e.target.checked)}
                        />
                      }
                      label="Cash on Delivery"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={saving}
                      startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
                    >
                      Save Payment Settings
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Notification Settings */}
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <NotificationsIcon />
                <Typography variant="h6">Notification Settings</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <form onSubmit={handleNotificationSettingsSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Email Notifications
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={settings.notification.email.orderConfirmation}
                          onChange={(e) => handleNestedInputChange('notification', 'email', 'orderConfirmation', e.target.checked)}
                        />
                      }
                      label="Order Confirmation"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={settings.notification.email.orderStatusUpdate}
                          onChange={(e) => handleNestedInputChange('notification', 'email', 'orderStatusUpdate', e.target.checked)}
                        />
                      }
                      label="Order Status Updates"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={settings.notification.email.stockAlert}
                          onChange={(e) => handleNestedInputChange('notification', 'email', 'stockAlert', e.target.checked)}
                        />
                      }
                      label="Stock Alerts"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Stock Alert Threshold"
                      value={settings.notification.email.stockAlertThreshold}
                      onChange={(e) => handleNestedInputChange('notification', 'email', 'stockAlertThreshold', Number(e.target.value))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={saving}
                      startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
                    >
                      Save Notification Settings
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Regional Settings */}
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LanguageIcon />
                <Typography variant="h6">Regional Settings</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <form onSubmit={handleRegionalSettingsSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Timezone"
                      value={settings.regional.timezone}
                      onChange={(e) => handleInputChange('regional', 'timezone', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Date Format"
                      value={settings.regional.dateFormat}
                      onChange={(e) => handleInputChange('regional', 'dateFormat', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Time Format</InputLabel>
                      <Select
                        value={settings.regional.timeFormat}
                        onChange={(e) => handleInputChange('regional', 'timeFormat', e.target.value)}
                        label="Time Format"
                      >
                        <MenuItem value="12h">12 Hour</MenuItem>
                        <MenuItem value="24h">24 Hour</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Default Currency"
                      value={settings.regional.defaultCurrency}
                      onChange={(e) => handleInputChange('regional', 'defaultCurrency', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={saving}
                      startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
                    >
                      Save Regional Settings
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Settings; 