import React from 'react';
import { ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Box, styled } from '@mui/material';
import { format } from 'date-fns';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1.5),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  cursor: 'pointer',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const NotificationAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  backgroundColor: 'transparent',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '50%',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '0.95rem',
  fontWeight: 500,
  color: theme.palette.text.primary,
  marginBottom: 4,
}));

const Message = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
  lineHeight: 1.4,
}));

const TimeStamp = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
  position: 'absolute',
  top: theme.spacing(1.5),
  right: theme.spacing(1.5),
}));

const getTimeDisplay = (date) => {
  const now = new Date();
  const notificationDate = new Date(date);
  const diffInHours = Math.floor((now - notificationDate) / (1000 * 60 * 60));
  
  if (diffInHours < 24) {
    return format(notificationDate, 'h:mm a');
  } else if (diffInHours < 48) {
    return 'Yesterday';
  } else {
    return format(notificationDate, 'MMM d');
  }
};

const NotificationItem = ({ notification, onClick }) => {
  const { title, message, image, createdAt, type } = notification;

  return (
    <StyledListItem onClick={onClick}>
      <ListItemAvatar>
        <NotificationAvatar>
          <img src={image} alt="" />
        </NotificationAvatar>
      </ListItemAvatar>
      <ListItemText
        primary={<Title>{title}</Title>}
        secondary={<Message>{message}</Message>}
        sx={{ pr: 7 }}
      />
      <TimeStamp>{getTimeDisplay(createdAt)}</TimeStamp>
    </StyledListItem>
  );
};

export default NotificationItem;
