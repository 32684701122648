import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { motion } from 'framer-motion';

const CategoryCard = ({ category, icon }) => {
  return (
    <Card
      component={motion.div}
      whileHover={{ scale: 1.05 }}
      sx={{
        height: '100%',
        textAlign: 'center',
        backgroundColor: 'primary.light',
        color: 'white',
      }}
    >
      <CardActionArea
        component={RouterLink}
        to={`/products?category=${category}`}
        sx={{ height: '100%' }}
      >
        <CardContent>
          {icon}
          <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
            {category}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CategoryCard; 