import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Divider,
  styled,
} from '@mui/material';
import {
  LocalShipping,
  Discount,
  NewReleases,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { deleteNotification } from '../features/notifications/notificationSlice';

// Styled components
const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const TimeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.75rem',
}));

const NotificationAvatar = styled(Avatar)(({ theme, type }) => {
  const colors = {
    order: '#1E88E5', // Blue for order notifications
    promo: '#7CB342', // Green for promo notifications
    sale: '#E53935', // Red for sale notifications
    default: theme.palette.primary.main,
  };
  
  return {
    backgroundColor: colors[type] || colors.default,
  };
});

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  '& .count': {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '2px 8px',
    borderRadius: 12,
    fontSize: '0.75rem',
  },
}));

const NotificationsTab = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifications);

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'order':
        return <LocalShipping />;
      case 'promo':
        return <Discount />;
      case 'sale':
        return <NewReleases />;
      default:
        return <NewReleases />;
    }
  };

  const getTimeDisplay = (date) => {
    const now = new Date();
    const notificationDate = new Date(date);
    const diffInHours = Math.floor((now - notificationDate) / (1000 * 60 * 60));
    
    if (diffInHours < 24) {
      return format(notificationDate, 'h:mm a');
    } else if (diffInHours < 48) {
      return 'Yesterday';
    } else {
      return format(notificationDate, 'd MMM');
    }
  };

  const handleDelete = (id) => {
    dispatch(deleteNotification(id));
  };

  // Separate notifications into new and earlier
  const newNotifications = notifications.filter(n => 
    (new Date() - new Date(n.createdAt)) < (24 * 60 * 60 * 1000)
  );
  const earlierNotifications = notifications.filter(n => 
    (new Date() - new Date(n.createdAt)) >= (24 * 60 * 60 * 1000)
  );

  const renderNotificationList = (notificationsList) => (
    <List>
      {notificationsList.map((notification) => (
        <StyledListItem
          key={notification._id}
          secondaryAction={
            <IconButton edge="end" onClick={() => handleDelete(notification._id)}>
              <CloseIcon />
            </IconButton>
          }
        >
          <ListItemAvatar>
            <NotificationAvatar type={notification.type}>
              {getNotificationIcon(notification.type)}
            </NotificationAvatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box component="div" sx={{ pr: 3 }}>
                <Typography variant="body1" component="span">
                  {notification.message}
                </Typography>
              </Box>
            }
            secondary={
              <TimeText>
                {getTimeDisplay(notification.createdAt)}
              </TimeText>
            }
          />
        </StyledListItem>
      ))}
    </List>
  );

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto' }}>
      {newNotifications.length > 0 && (
        <>
          <SectionTitle variant="h6">
            New <span className="count">{newNotifications.length}</span>
          </SectionTitle>
          {renderNotificationList(newNotifications)}
          <Divider sx={{ my: 3 }} />
        </>
      )}

      {earlierNotifications.length > 0 && (
        <>
          <SectionTitle variant="h6">
            Earlier <span className="count">{earlierNotifications.length}</span>
          </SectionTitle>
          {renderNotificationList(earlierNotifications)}
        </>
      )}

      {notifications.length === 0 && (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography color="textSecondary">
            No notifications yet
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default NotificationsTab;
