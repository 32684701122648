import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';

// Async thunk to fetch cart from server
export const fetchCart = createAsyncThunk(
  'cart/fetchCart',
  async (_, { getState }) => {
    const { auth } = getState();
    if (!auth.user) return { items: [] };
    const { data } = await api.get('/api/cart');
    console.log('Fetched cart data:', data); // Debug log
    return data;
  }
);

// Async thunk to add item to cart
export const addToCartAsync = createAsyncThunk(
  'cart/addToCart',
  async ({ productId, quantity }, { getState }) => {
    const { auth } = getState();
    if (!auth.user) {
      throw new Error('Please login to add items to cart');
    }
    console.log('Adding to cart:', { productId, quantity }); // Debug log
    const { data } = await api.post('/api/cart', { productId, quantity });
    console.log('Cart after adding item:', data); // Debug log
    return data;
  }
);

// Async thunk to update cart item quantity
export const updateQuantityAsync = createAsyncThunk(
  'cart/updateQuantity',
  async ({ itemId, quantity }, { getState }) => {
    const { auth } = getState();
    if (!auth.user) {
      throw new Error('Please login to update cart');
    }
    console.log('Updating cart item:', { itemId, quantity }); // Debug log
    const { data } = await api.put(`/api/cart/item/${itemId}`, { quantity });
    console.log('Cart after update:', data); // Debug log
    return data;
  }
);

// Async thunk to remove item from cart
export const removeFromCartAsync = createAsyncThunk(
  'cart/removeFromCart',
  async (itemId, { getState }) => {
    const { auth } = getState();
    if (!auth.user) {
      throw new Error('Please login to remove items from cart');
    }
    const { data } = await api.delete(`/api/cart/item/${itemId}`);
    return data;
  }
);

// Async thunk to clear cart
export const clearCartAsync = createAsyncThunk(
  'cart/clearCart',
  async (_, { getState }) => {
    const { auth } = getState();
    if (!auth.user) {
      throw new Error('Please login to clear cart');
    }
    const { data } = await api.delete('/api/cart');
    return data;
  }
);

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    loading: false,
    error: null,
    subtotal: 0,
    tax: 0,
    shippingFee: 0,
    total: 0
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch cart
      .addCase(fetchCart.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.loading = false;
        // Ensure each item has the correct product ID format
        state.items = action.payload.items.map(item => {
          const productId = item.product?._id || item.product;
          console.log('Processing cart item:', { 
            original: item,
            extractedProductId: productId 
          }); // Debug log
          
          if (!productId) {
            console.error('Missing product ID for item:', item);
          }

          return {
            ...item,
            product: productId, // Store just the ID
            name: item.name || item.product?.name,
            price: item.price || item.product?.price,
            image: item.image || item.product?.thumbnail || item.product?.images?.[0]
          };
        });
        state.subtotal = action.payload.subtotal;
        state.tax = action.payload.tax;
        state.shippingFee = action.payload.shippingFee;
        state.total = action.payload.total;
      })
      .addCase(fetchCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Add to cart
      .addCase(addToCartAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addToCartAsync.fulfilled, (state, action) => {
        state.loading = false;
        // Ensure each item has the correct product ID format
        state.items = action.payload.items.map(item => {
          const productId = item.product?._id || item.product;
          console.log('Processing added item:', { 
            original: item,
            extractedProductId: productId 
          }); // Debug log

          return {
            ...item,
            product: productId, // Store just the ID
            name: item.name || item.product?.name,
            price: item.price || item.product?.price,
            image: item.image || item.product?.thumbnail || item.product?.images?.[0]
          };
        });
        state.subtotal = action.payload.subtotal;
        state.tax = action.payload.tax;
        state.shippingFee = action.payload.shippingFee;
        state.total = action.payload.total;
      })
      .addCase(addToCartAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Update quantity
      .addCase(updateQuantityAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateQuantityAsync.fulfilled, (state, action) => {
        state.loading = false;
        // Ensure each item has the correct product ID format
        state.items = action.payload.items.map(item => {
          const productId = item.product?._id || item.product;
          console.log('Processing updated item:', { 
            original: item,
            extractedProductId: productId 
          }); // Debug log

          return {
            ...item,
            product: productId, // Store just the ID
            name: item.name || item.product?.name,
            price: item.price || item.product?.price,
            image: item.image || item.product?.thumbnail || item.product?.images?.[0]
          };
        });
        state.subtotal = action.payload.subtotal;
        state.tax = action.payload.tax;
        state.shippingFee = action.payload.shippingFee;
        state.total = action.payload.total;
      })
      .addCase(updateQuantityAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Remove from cart
      .addCase(removeFromCartAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeFromCartAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload.items;
        state.subtotal = action.payload.subtotal;
        state.tax = action.payload.tax;
        state.shippingFee = action.payload.shippingFee;
        state.total = action.payload.total;
      })
      .addCase(removeFromCartAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Clear cart
      .addCase(clearCartAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(clearCartAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.items = [];
        state.subtotal = 0;
        state.tax = 0;
        state.shippingFee = 0;
        state.total = 0;
      })
      .addCase(clearCartAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { clearError } = cartSlice.actions;
export default cartSlice.reducer; 