import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  Divider,
  Card,
  CardContent,
  useTheme,
  LinearProgress,
  Menu,
  MenuItem,
  alpha,
} from '@mui/material';
import {
  ShoppingCart as CartIcon,
  People as PeopleIcon,
  Inventory as InventoryIcon,
  AttachMoney as MoneyIcon,
  Add as AddIcon,
  Visibility as ViewIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  MoreVert as MoreVertIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import LoadingSpinner from '../../components/LoadingSpinner';
import {
  fetchDashboardStats,
  fetchSalesTrends,
  fetchTopProducts,
  fetchRecentOrders,
} from '../../features/dashboard/dashboardSlice';

const Dashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [periodMenuAnchor, setPeriodMenuAnchor] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState('month');

  const {
    stats,
    salesTrends,
    topProducts,
    recentOrders,
    loading,
    error,
  } = useSelector((state) => state.dashboard);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(fetchDashboardStats()).unwrap(),
          dispatch(fetchSalesTrends(selectedPeriod)).unwrap(),
          dispatch(fetchTopProducts(5)).unwrap(),
          dispatch(fetchRecentOrders(5)).unwrap(),
        ]);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchData();
  }, [dispatch, selectedPeriod]);

  const handleRefreshData = () => {
    dispatch(fetchDashboardStats());
    dispatch(fetchSalesTrends(selectedPeriod));
    dispatch(fetchTopProducts(5));
    dispatch(fetchRecentOrders(5));
  };

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
    setPeriodMenuAnchor(null);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-GH', {
      style: 'currency',
      currency: 'GHS',
      minimumFractionDigits: 2,
    }).format(amount);
  };

  const StatCard = ({ title, value, icon, color, trend }) => (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Avatar sx={{ bgcolor: color, mr: 2 }}>{icon}</Avatar>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </Box>
        <Typography variant="h4" component="div" sx={{ mb: 1 }}>
          {typeof value === 'number' && title.includes('Total Sales')
            ? formatCurrency(value)
            : value}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {trend > 0 ? (
            <TrendingUpIcon color="success" sx={{ mr: 1 }} />
          ) : (
            <TrendingDownIcon color="error" sx={{ mr: 1 }} />
          )}
          <Typography
            variant="body2"
            color={trend > 0 ? 'success.main' : 'error.main'}
          >
            {Math.abs(trend)}% from last {selectedPeriod === 'day' ? 'day' : selectedPeriod === 'week' ? 'week' : selectedPeriod === 'month' ? 'month' : 'year'}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );

  if (loading && !salesTrends.length) return <LoadingSpinner />;

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" component="h1">
          Dashboard
        </Typography>
        <Box>
          <Button
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={handleRefreshData}
            sx={{ mr: 2 }}
          >
            Refresh
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate('/admin/products')}
          >
            Add Product
          </Button>
        </Box>
      </Box>

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {/* Statistics Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Sales"
            value={stats.totalSales}
            icon={<MoneyIcon />}
            color={theme.palette.primary.main}
            trend={stats.trends?.sales || 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Orders"
            value={stats.totalOrders}
            icon={<CartIcon />}
            color={theme.palette.secondary.main}
            trend={stats.trends?.orders || 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Products"
            value={stats.totalProducts}
            icon={<InventoryIcon />}
            color={theme.palette.success.main}
            trend={stats.trends?.products || 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Customers"
            value={stats.totalCustomers}
            icon={<PeopleIcon />}
            color={theme.palette.info.main}
            trend={stats.trends?.customers || 0}
          />
        </Grid>
      </Grid>

      {/* Sales Trends and Top Products */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h6">Sales by Category</Typography>
              <Button
                endIcon={<MoreVertIcon />}
                onClick={(e) => setPeriodMenuAnchor(e.currentTarget)}
              >
                {selectedPeriod === 'day' ? 'Today' :
                 selectedPeriod === 'week' ? 'This Week' :
                 selectedPeriod === 'month' ? 'This Month' : 'This Year'}
              </Button>
              <Menu
                anchorEl={periodMenuAnchor}
                open={Boolean(periodMenuAnchor)}
                onClose={() => setPeriodMenuAnchor(null)}
              >
                <MenuItem onClick={() => handlePeriodChange('day')}>Today</MenuItem>
                <MenuItem onClick={() => handlePeriodChange('week')}>This Week</MenuItem>
                <MenuItem onClick={() => handlePeriodChange('month')}>This Month</MenuItem>
                <MenuItem onClick={() => handlePeriodChange('year')}>This Year</MenuItem>
              </Menu>
            </Box>
            <Box sx={{ mt: 4 }}>
              {salesTrends.map((item) => (
                <Box key={item.category} sx={{ mb: 3 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Box>
                      <Typography variant="body1">{item.category}</Typography>
                      <Typography variant="caption" color="text.secondary">
                        {item.quantity} items sold
                      </Typography>
                    </Box>
                    <Typography variant="body1" color="text.secondary">
                      {formatCurrency(item.amount)}
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={item.percentage}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      '& .MuiLinearProgress-bar': {
                        borderRadius: 4,
                        backgroundColor: theme.palette.primary.main,
                      },
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Top Products
            </Typography>
            <List>
              {topProducts.map((product) => (
                <React.Fragment key={product._id}>
                  <ListItem
                    secondaryAction={
                      <IconButton edge="end" onClick={() => navigate(`/admin/products/${product._id}`)}>
                        <ViewIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={product.name}
                      secondary={
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="body2" color="text.secondary" gutterBottom>
                            {`${product.quantity} sold - ${formatCurrency(product.revenue)}`}
                          </Typography>
                          <Typography variant="caption" color="text.secondary" display="block">
                            Category: {product.category}
                          </Typography>
                          <LinearProgress
                            variant="determinate"
                            value={product.progress}
                            sx={{
                              height: 4,
                              borderRadius: 2,
                            }}
                          />
                        </Box>
                      }
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>

      {/* Recent Orders */}
      <Paper sx={{ p: 3 }}>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Recent Orders</Typography>
          <Button
            variant="outlined"
            size="small"
            onClick={() => navigate('/admin/orders')}
          >
            View All
          </Button>
        </Box>
        <List>
          {recentOrders.map((order) => (
            <React.Fragment key={order._id}>
              <ListItem
                secondaryAction={
                  <IconButton edge="end" onClick={() => navigate(`/admin/orders/${order._id}`)}>
                    <ViewIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    {order.customer?.name ? order.customer.name[0] : 'G'}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={order.customer?.name || 'Guest User'}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {formatCurrency(order.amount)}
                      </Typography>
                      {` - ${order.status} - ${new Date(order.date).toLocaleDateString()}`}
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default Dashboard;