import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Box,
  Grid,
  Chip,
  Button,
  Divider,
  useTheme,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Rating,
  TextField,
  IconButton,
  CircularProgress,
  Card,
  CardContent,
} from '@mui/material';
import {
  LocalShipping as ShippingIcon,
  AccessTime as TimeIcon,
  LocationOn as LocationIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Star as StarIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import LoadingSpinner from '../components/LoadingSpinner';
import api from '../utils/api';
import { getImageUrl } from '../utils/imageUtils';

const OrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ratingDialog, setRatingDialog] = useState({
    open: false,
    productId: null,
    productName: '',
    rating: 0,
    comment: '',
  });

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const { data } = await api.get(`/orders/${id}`);
        setOrder(data);
      } catch (err) {
        setError(err.response?.data?.message || 'Error fetching order details');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchOrderDetails();
    }
  }, [id]);

  const handleRatingSubmit = async () => {
    try {
      if (!ratingDialog.productId) {
        setError('Product ID is missing');
        return;
      }

      // Convert productId to string if it's an object
      const productId = typeof ratingDialog.productId === 'object' ? 
        ratingDialog.productId._id || ratingDialog.productId.toString() : 
        ratingDialog.productId;

      console.log('Submitting review for product:', productId); // Debug log

      await api.post(`/products/${productId}/reviews`, {
        rating: ratingDialog.rating,
        comment: ratingDialog.comment,
        orderId: id
      });
      
      // Refresh order details to update review status
      const { data } = await api.get(`/orders/${id}`);
      setOrder(data);
      
      // Close dialog and reset
      setRatingDialog({
        open: false,
        productId: null,
        productName: '',
        rating: 0,
        comment: '',
      });
    } catch (err) {
      setError(err.response?.data?.message || 'Error submitting review');
      console.error('Review submission error:', err);
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'pending':
        return 'warning';
      case 'processing':
        return 'info';
      case 'shipped':
        return 'primary';
      case 'delivered':
        return 'success';
      case 'cancelled':
        return 'error';
      default:
        return 'default';
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!order) return <Alert severity="info">Order not found</Alert>;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Order Details
        </Typography>
        
        <Grid container spacing={4}>
          {/* Order Items */}
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              Order Items
            </Typography>
            {order.items && order.items.map((item) => (
              <Card key={item._id} sx={{ mb: 2 }}>
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                      <img
                        src={getImageUrl(item)}
                        alt={item.name}
                        style={{ width: '100%', height: 'auto' }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/placeholder.jpg';
                        }}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Box>
                          <Typography variant="h6">{item.name}</Typography>
                          <Typography>Quantity: {item.quantity}</Typography>
                          <Typography>Price: GH₵{item.price}</Typography>
                          <Typography>Subtotal: GH₵{item.price * item.quantity}</Typography>
                        </Box>
                        {order.status === 'delivered' && !item.reviewed && (
                          <Button
                            variant="outlined"
                            size="small"
                            startIcon={<StarIcon />}
                            onClick={() => {
                              // Get the product ID string
                              const productId = typeof item.product === 'object' ? 
                                item.product._id || item.product.toString() : 
                                item.product;
                              
                              console.log('Setting rating dialog for product:', productId); // Debug log
                              
                              setRatingDialog({
                                open: true,
                                productId: productId,
                                productName: item.name,
                                rating: 0,
                                comment: '',
                              });
                            }}
                          >
                            Rate Product
                          </Button>
                        )}
                        {item.reviewed && (
                          <Box sx={{ textAlign: 'right' }}>
                            <Rating value={item.rating} readOnly size="small" />
                            <Typography variant="caption" display="block">
                              Reviewed
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </Grid>

          {/* Order Info */}
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Order Summary
                </Typography>
                <Divider sx={{ my: 2 }} />
                
                <Typography gutterBottom>
                  Subtotal: GH₵{order.subtotal}
                </Typography>
                <Typography gutterBottom>
                  Shipping: GH₵{order.shippingCost}
                </Typography>
                <Typography gutterBottom>
                  Tax: GH₵{order.tax}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Total: GH₵{order.total}
                </Typography>

                <Divider sx={{ my: 2 }} />
                
                <Typography variant="h6" gutterBottom>
                  Delivery Address:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {order.deliveryAddress?.street}<br />
                  {order.deliveryAddress?.city}, {order.deliveryAddress?.state}<br />
                  {order.deliveryAddress?.country}
                  {order.deliveryAddress?.zipCode && ` - ${order.deliveryAddress.zipCode}`}
                </Typography>

                <Divider sx={{ my: 2 }} />
                
                <Typography variant="h6" gutterBottom>
                  Payment Status
                </Typography>
                <Chip
                  label={order.isPaid ? 'Paid' : 'Pending'}
                  color={order.isPaid ? 'success' : 'warning'}
                  sx={{ mt: 1 }}
                />

                <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
                  Order Status
                </Typography>
                <Chip
                  label={order.status}
                  color={
                    order.status === 'delivered' ? 'success' :
                    order.status === 'processing' ? 'info' :
                    'default'
                  }
                  sx={{ mt: 1 }}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>

      {/* Rating Dialog */}
      <Dialog 
        open={ratingDialog.open} 
        onClose={() => setRatingDialog({ ...ratingDialog, open: false })}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Rate Product: {ratingDialog.productName}
          <IconButton
            onClick={() => setRatingDialog({ ...ratingDialog, open: false })}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Rating
                value={ratingDialog.rating}
                onChange={(event, newValue) => setRatingDialog({ ...ratingDialog, rating: newValue })}
                size="large"
              />
            </Box>
            <TextField
              label="Your Review"
              multiline
              rows={4}
              value={ratingDialog.comment}
              onChange={(e) => setRatingDialog({ ...ratingDialog, comment: e.target.value })}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRatingDialog({ ...ratingDialog, open: false })}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleRatingSubmit}
            disabled={!ratingDialog.rating || !ratingDialog.comment}
          >
            Submit Review
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default OrderDetails;