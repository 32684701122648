import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  Chip,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Payment as PaymentIcon,
} from '@mui/icons-material';
import api from '../utils/api';

const MyServiceRequests = () => {
  const navigate = useNavigate();
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      const { data } = await api.get('/service-requests/my-requests');
      setRequests(data);
    } catch (err) {
      setError('Failed to load service requests');
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptQuote = async (requestId) => {
    try {
      await api.post(`/service-requests/${requestId}/accept-quote`);
      fetchRequests();
    } catch (err) {
      setError('Failed to accept quote');
    }
  };

  const handleCancel = async (requestId) => {
    try {
      await api.post(`/service-requests/${requestId}/cancel`);
      fetchRequests();
    } catch (err) {
      setError('Failed to cancel request');
    }
  };

  const handlePayment = async (request) => {
    try {
      // Convert service request items to order items format
      const orderItems = request.items.map(item => ({
        name: item.name,
        price: item.estimatedPrice,
        quantity: item.quantity,
        image: item.images?.[0] || null,
        serviceRequestId: request._id,
        isServiceItem: true
      }));

      // Store checkout data in localStorage instead of sessionStorage
      localStorage.setItem('checkoutData', JSON.stringify({
        items: orderItems,
        total: request.totalAmount,
        serviceRequestId: request._id,
        isServiceOrder: true
      }));

      // Navigate directly to checkout
      navigate('/checkout', { 
        state: { 
          fromServiceRequest: true,
          serviceRequestId: request._id 
        } 
      });
    } catch (err) {
      console.error('Error processing payment:', err);
      setError('Failed to process payment');
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'quoted':
        return 'info';
      case 'accepted':
        return 'primary';
      case 'paid':
        return 'success';
      case 'completed':
        return 'success';
      case 'cancelled':
        return 'error';
      default:
        return 'default';
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
      <CircularProgress />
    </Box>
  );
  
  if (error) return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Alert severity="error">{error}</Alert>
    </Container>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
        <Typography variant="h4">
          My Service Requests
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate('/service-requests/new')}
        >
          New Request
        </Button>
      </Box>

      <Grid container spacing={3}>
        {requests.map((request) => (
          <Grid item xs={12} key={request._id}>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography variant="h6">
                    Request #{request._id.slice(-6)}
                  </Typography>
                  <Chip
                    label={request.status.toUpperCase()}
                    color={getStatusColor(request.status)}
                  />
                </Box>

                <Typography color="text.secondary" gutterBottom>
                  Submitted on {formatDate(request.createdAt)}
                </Typography>

                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Requested Items:
                  </Typography>
                  <Grid container spacing={2}>
                    {request.items.map((item, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography variant="subtitle2">
                              {item.name}
                            </Typography>
                            <Typography color="text.secondary">
                              Quantity: {item.quantity}
                            </Typography>
                            {item.estimatedPrice > 0 && (
                              <Typography color="primary">
                                Price: GH₵{item.estimatedPrice.toFixed(2)}
                              </Typography>
                            )}
                            {item.images?.length > 0 && (
                              <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                                {item.images.map((image, imgIndex) => (
                                  <img
                                    key={imgIndex}
                                    src={image}
                                    alt={`Item ${index + 1}`}
                                    style={{
                                      width: 50,
                                      height: 50,
                                      objectFit: 'cover',
                                      borderRadius: 4
                                    }}
                                  />
                                ))}
                              </Box>
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                {request.totalAmount > 0 && (
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Total Amount: GH₵{request.totalAmount.toFixed(2)}
                  </Typography>
                )}

                {request.adminNotes && (
                  <Alert severity="info" sx={{ mt: 2 }}>
                    {request.adminNotes}
                  </Alert>
                )}

                <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                  {request.status === 'quoted' && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CheckCircleIcon />}
                        onClick={() => handleAcceptQuote(request._id)}
                      >
                        Accept Quote
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<CancelIcon />}
                        onClick={() => handleCancel(request._id)}
                      >
                        Decline
                      </Button>
                    </>
                  )}

                  {request.status === 'accepted' && (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<PaymentIcon />}
                      onClick={() => handlePayment(request)}
                    >
                      Pay Now
                    </Button>
                  )}

                  {['pending', 'quoted'].includes(request.status) && (
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<CancelIcon />}
                      onClick={() => handleCancel(request._id)}
                    >
                      Cancel Request
                    </Button>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {requests.length === 0 && (
          <Grid item xs={12}>
            <Alert severity="info">
              You haven't made any service requests yet.
            </Alert>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default MyServiceRequests; 