import axios from 'axios';

// Create axios instance with default config
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add auth token to requests if available
api.interceptors.request.use(
  (config) => {
    // For multipart/form-data, let the browser set the Content-Type
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    }
    
    // Remove duplicate /api in URL if present
    if (config.url.startsWith('/api/')) {
      config.url = config.url.substring(4);
    }
    
    // Log request for debugging
    console.log('API Request:', {
      method: config.method,
      url: config.url,
      data: config.data,
      headers: config.headers,
    });
    
    const user = localStorage.getItem('user');
    if (user) {
      const { token } = JSON.parse(user);
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Handle response errors
api.interceptors.response.use(
  (response) => {
    // Log response for debugging
    console.log('API Response:', {
      status: response.status,
      data: response.data,
    });
    return response;
  },
  (error) => {
    console.error('Response error:', error.response || error);
    
    if (!error.response) {
      // Network error
      return Promise.reject({
        message: 'Unable to connect to the server. Please check your internet connection or try again later.',
      });
    }

    if (error.response.status === 401) {
      localStorage.removeItem('user');
      window.location.href = '/login';
      return Promise.reject({
        message: 'Your session has expired. Please log in again.',
      });
    }

    // Return a more user-friendly error message
    return Promise.reject({
      message: error.response?.data?.message || 'An error occurred. Please try again.',
      status: error.response?.status,
      data: error.response?.data,
    });
  }
);

export default api; 