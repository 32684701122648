import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Button,
  Box,
} from '@mui/material';
import {
  ShoppingCart as CartIcon,
} from '@mui/icons-material';
import { motion } from 'framer-motion';

const EmptyCart = () => {
  const navigate = useNavigate();

  return (
    <Container 
      maxWidth="md" 
      sx={{ 
        py: 8, 
        textAlign: 'center',
        minHeight: '60vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <CartIcon 
          sx={{ 
            fontSize: 100, 
            color: 'text.secondary', 
            mb: 3,
            opacity: 0.5 
          }} 
        />
      </motion.div>

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        <Typography variant="h4" gutterBottom>
          Your Cart is Empty
        </Typography>
      </motion.div>

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        <Typography 
          variant="body1" 
          color="text.secondary" 
          paragraph
          sx={{ mb: 4 }}
        >
          Looks like you haven't added any items to your cart yet.
          Browse our products and find something you like!
        </Typography>
      </motion.div>

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4 }}
      >
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            size="large"
            startIcon={<CartIcon />}
            onClick={() => navigate('/products')}
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 2,
              textTransform: 'none',
              fontSize: '1.1rem',
            }}
          >
            Start Shopping
          </Button>
        </Box>
      </motion.div>
    </Container>
  );
};

export default EmptyCart; 