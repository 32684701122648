import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Container,
  Card,
  CardContent,
} from '@mui/material';
import api from '../../utils/api';
import LoadingSpinner from '../../components/LoadingSpinner';
import { getImageUrl } from '../../utils/imageUtils';

const AdminOrderDetails = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updateStatus, setUpdateStatus] = useState('');

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const { data } = await api.get(`/orders/${id}`);
        setOrder(data);
        setUpdateStatus(data.status);
      } catch (err) {
        setError(err.response?.data?.message || 'Error fetching order details');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchOrder();
    }
  }, [id]);

  const handleStatusUpdate = async () => {
    try {
      const { data } = await api.patch(`/orders/${id}`, { status: updateStatus });
      setOrder(data);
      // Show success message or notification here if needed
    } catch (err) {
      setError(err.response?.data?.message || 'Error updating order status');
      console.error('Error updating order status:', err);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!order) return <Alert severity="info">Order not found</Alert>;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <Typography variant="h4">
            Order Details
          </Typography>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Order Status</InputLabel>
            <Select
              value={updateStatus}
              onChange={(e) => setUpdateStatus(e.target.value)}
              label="Order Status"
            >
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="processing">Processing</MenuItem>
              <MenuItem value="shipped">Shipped</MenuItem>
              <MenuItem value="delivered">Delivered</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
            </Select>
            <Button
              variant="contained"
              onClick={handleStatusUpdate}
              disabled={updateStatus === order.status}
              sx={{ mt: 1 }}
            >
              Update Status
            </Button>
          </FormControl>
        </Box>
        
        <Grid container spacing={4}>
          {/* Order Items */}
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              Order Items
            </Typography>
            {order.items && order.items.map((item) => (
              <Card key={item._id} sx={{ mb: 2 }}>
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                      <img
                        src={getImageUrl(item)}
                        alt={item.name}
                        style={{
                          width: '100%',
                          height: 'auto',
                          maxHeight: '120px',
                          objectFit: 'contain'
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/placeholder.jpg';
                        }}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="h6">{item.name}</Typography>
                      <Typography>Quantity: {item.quantity}</Typography>
                      <Typography>Price: GH₵{item.price}</Typography>
                      <Typography>Subtotal: GH₵{item.price * item.quantity}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </Grid>

          {/* Order Info */}
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Customer Information
                </Typography>
                <Typography>Name: {order.user?.name}</Typography>
                <Typography>Email: {order.user?.email}</Typography>

                <Divider sx={{ my: 2 }} />
                
                <Typography variant="h6" gutterBottom>
                  Order Summary
                </Typography>
                <Typography>Subtotal: GH₵{order.subtotal}</Typography>
                <Typography>Shipping: GH₵{order.shippingCost}</Typography>
                <Typography>Tax: GH��{order.tax}</Typography>
                <Typography variant="h6">
                  Total: GH₵{order.total}
                </Typography>

                <Divider sx={{ my: 2 }} />
                
                <Typography variant="h6" gutterBottom>
                  Shipping Information
                </Typography>
                {order.shippingAddress && (
                  <>
                    <Typography>{order.shippingAddress.street}</Typography>
                    <Typography>{order.shippingAddress.city}</Typography>
                    <Typography>{order.shippingAddress.state}</Typography>
                    <Typography>{order.shippingAddress.country}</Typography>
                  </>
                )}

                <Divider sx={{ my: 2 }} />
                
                <Typography variant="h6" gutterBottom>
                  Payment Status
                </Typography>
                <Chip
                  label={order.isPaid ? 'Paid' : 'Pending'}
                  color={order.isPaid ? 'success' : 'warning'}
                  sx={{ mt: 1 }}
                />

                <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
                  Order Status
                </Typography>
                <Chip
                  label={order.status}
                  color={
                    order.status === 'delivered' ? 'success' :
                    order.status === 'processing' ? 'info' :
                    'default'
                  }
                  sx={{ mt: 1 }}
                />

                <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                  Order Date: {new Date(order.createdAt).toLocaleString()}
                </Typography>
                {order.updatedAt && (
                  <Typography variant="body2" color="text.secondary">
                    Last Updated: {new Date(order.updatedAt).toLocaleString()}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default AdminOrderDetails; 