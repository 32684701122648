import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Button,
  Box,
  Paper,
  CircularProgress,
  Alert
} from '@mui/material';
import { CheckCircleOutline as CheckIcon } from '@mui/icons-material';
import api from '../utils/api';

const OrderSuccess = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const orderId = localStorage.getItem('lastOrderId');
        if (!orderId) {
          setError('No order ID found');
          setLoading(false);
          return;
        }

        const { data } = await api.get(`/api/orders/${orderId}`);
        setOrder(data);
        // Clear the order ID from localStorage
        localStorage.removeItem('lastOrderId');
      } catch (error) {
        console.error('Error fetching order details:', error);
        setError(error.response?.data?.message || 'Error fetching order details');
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, []);

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ py: 8, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm" sx={{ py: 8 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
        <Button
          component={RouterLink}
          to="/"
          variant="contained"
          fullWidth
        >
          Return to Home
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ py: 8 }}>
      <Paper sx={{ p: 4, textAlign: 'center' }}>
        <CheckIcon color="success" sx={{ fontSize: 64, mb: 2 }} />
        <Typography variant="h4" gutterBottom>
          Order Successful!
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Thank you for your order. Your order has been successfully placed and is being processed.
        </Typography>

        {order && (
          <Box sx={{ mt: 3, mb: 4, textAlign: 'left' }}>
            <Typography variant="h6" gutterBottom>
              Order Details
            </Typography>
            <Typography variant="body2" paragraph>
              Order ID: {order._id}
            </Typography>
            <Typography variant="body2" paragraph>
              Total Amount: GHS {order.total.toFixed(2)}
            </Typography>
            <Typography variant="body2" paragraph>
              Payment Status: {order.paymentDetails?.status || 'N/A'}
            </Typography>
            <Typography variant="body2">
              Delivery Address:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {order.deliveryAddress?.street}<br />
              {order.deliveryAddress?.city}, {order.deliveryAddress?.state}<br />
              {order.deliveryAddress?.country}
              {order.deliveryAddress?.zipCode && ` - ${order.deliveryAddress.zipCode}`}
            </Typography>
          </Box>
        )}

        <Box sx={{ mt: 4, display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' } }}>
          <Button
            component={RouterLink}
            to={`/orders/${order?._id}`}
            variant="contained"
            fullWidth
          >
            View Order Details
          </Button>
          <Button
            component={RouterLink}
            to="/"
            variant="outlined"
            fullWidth
          >
            Continue Shopping
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default OrderSuccess;