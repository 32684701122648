// Helper function to get the correct image URL from various sources
export const getImageUrl = (item) => {
  // Try to get image from populated product first
  if (item?.product?.images?.length > 0) {
    return item.product.images[0];
  }
  
  // Try product thumbnail
  if (item?.product?.thumbnail) {
    return item.product.thumbnail;
  }
  
  // Try item's images array
  if (item?.images?.length > 0) {
    return item.images[0];
  }

  // Try item's thumbnail
  if (item?.thumbnail) {
    return item.thumbnail;
  }
  
  // Try item's direct image property
  if (item?.image) {
    return item.image;
  }

  // If the input is a direct image URL string
  if (typeof item === 'string' && item.startsWith('http')) {
    return item;
  }
  
  // Return placeholder if no image is found
  return '/placeholder.jpg';
}; 