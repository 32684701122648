import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Rating,
  Chip,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';

const ProductCard = ({ product }) => {
  const theme = useTheme();

  // Function to get image URL
  const getImageUrl = (imageUrl) => {
    // If it's already a full URL (Cloudinary), return as is
    if (imageUrl?.startsWith('http')) {
      return imageUrl;
    }
    // Fallback to placeholder if no image
    return '/placeholder.jpg';
  };

  return (
    <Card
      component={motion.div}
      whileHover={{ y: -5 }}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        '@media (max-width: 350px)': {
          '& .MuiCardContent-root': {
            padding: 1,
          },
        },
      }}
    >
      {product.discount > 0 && (
        <Chip
          label={`${product.discount}% OFF`}
          color="secondary"
          size="small"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 1,
            '@media (max-width: 350px)': {
              fontSize: '0.7rem',
              height: '20px',
            },
          }}
        />
      )}
      <RouterLink
        to={`/products/${product._id}`}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <CardMedia
          component="img"
          image={getImageUrl(product.thumbnail || product.images[0])}
          alt={product.name}
          sx={{
            height: { xs: 140, sm: 200 },
            objectFit: 'cover',
            '@media (max-width: 350px)': {
              height: 120,
            },
          }}
        />
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography
            gutterBottom
            variant="h6"
            component="h2"
            noWrap
            sx={{
              '@media (max-width: 350px)': {
                fontSize: '0.9rem',
                marginBottom: 0.5,
              },
            }}
          >
            {product.name}
          </Typography>
          <Box
            sx={{
              display: 'grid',
              alignItems: 'center',
              mb: 1,
              '@media (max-width: 350px)': {
                flexDirection: 'column',
                alignItems: 'flex-start',
              },
            }}
          >
            <Rating
              value={product.rating}
              readOnly
              size="small"
              sx={{
                '@media (max-width: 350px)': {
                  fontSize: '0.8rem',
                },
              }}
            />
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                ml: { xs: 0, sm: 1 },
                '@media (max-width: 350px)': {
                  fontSize: '0.7rem',
                  marginTop: 0.5,
                },
              }}
            >
              ({product.numReviews} reviews)
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              flexWrap: 'wrap',
              gap: 1,
            }}
          >
            <Typography
              variant="h6"
              color="primary"
              sx={{
                '@media (max-width: 350px)': {
                  fontSize: '1rem',
                },
              }}
            >
              GHS {product.price.toFixed(2)}
            </Typography>
            {product.discount > 0 && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  textDecoration: 'line-through',
                  '@media (max-width: 350px)': {
                    fontSize: '0.8rem',
                  },
                }}
              >
                GHS {(product.price / (1 - product.discount / 100)).toFixed(2)}
              </Typography>
            )}
          </Box>
        </CardContent>
      </RouterLink>
    </Card>
  );
};

export default ProductCard; 