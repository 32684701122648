import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './app/store';
import { ThemeProvider } from './contexts/ThemeContext';
import { SocketProvider } from './context/SocketContext';
import AppRoutes from './routes';
import ScrollToTop from './components/ScrollToTop';
import './App.css';

function App() {
  return (
    <Provider store={store}>
      <SocketProvider>
        <ThemeProvider>
          <BrowserRouter>
            <ScrollToTop />
            <AppRoutes />
          </BrowserRouter>
        </ThemeProvider>
      </SocketProvider>
    </Provider>
  );
}

export default App;
