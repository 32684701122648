import { createSlice } from '@reduxjs/toolkit';

// Load wishlist items from localStorage
const loadWishlistItems = () => {
  try {
    const wishlistItems = localStorage.getItem('wishlist');
    return wishlistItems ? JSON.parse(wishlistItems) : [];
  } catch (error) {
    console.error('Error loading wishlist from localStorage:', error);
    return [];
  }
};

// Save wishlist items to localStorage
const saveWishlistItems = (items) => {
  try {
    localStorage.setItem('wishlist', JSON.stringify(items));
  } catch (error) {
    console.error('Error saving wishlist to localStorage:', error);
  }
};

const initialState = {
  items: loadWishlistItems(),
  loading: false,
  error: null,
};

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    addToWishlist: (state, action) => {
      const newItem = action.payload;
      if (!state.items.some(item => item._id === newItem._id)) {
        state.items.push(newItem);
        saveWishlistItems(state.items);
      }
    },
    removeFromWishlist: (state, action) => {
      state.items = state.items.filter(item => item._id !== action.payload);
      saveWishlistItems(state.items);
    },
    clearWishlist: (state) => {
      state.items = [];
      saveWishlistItems(state.items);
    },
    setWishlistError: (state, action) => {
      state.error = action.payload;
    },
    clearWishlistError: (state) => {
      state.error = null;
    },
  },
});

export const {
  addToWishlist,
  removeFromWishlist,
  clearWishlist,
  setWishlistError,
  clearWishlistError,
} = wishlistSlice.actions;

export default wishlistSlice.reducer; 