import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const Privacy = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h2" component="h1" gutterBottom align="center">
        Privacy Policy
      </Typography>

      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography paragraph>
            We collect information that you provide directly to us, including:
          </Typography>
          <ul>
            <li>Name and contact information</li>
            <li>Account credentials</li>
            <li>Payment information</li>
            <li>Delivery address</li>
            <li>Shopping preferences and history</li>
          </ul>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            2. How We Use Your Information
          </Typography>
          <Typography paragraph>
            We use the information we collect to:
          </Typography>
          <ul>
            <li>Process your orders and payments</li>
            <li>Communicate with you about your orders</li>
            <li>Send you marketing communications (with your consent)</li>
            <li>Improve our services</li>
            <li>Prevent fraud and enhance security</li>
          </ul>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            3. Information Sharing
          </Typography>
          <Typography paragraph>
            We do not sell your personal information. We may share your
            information with:
          </Typography>
          <ul>
            <li>Service providers who assist in our operations</li>
            <li>Payment processors</li>
            <li>Delivery partners</li>
            <li>Law enforcement when required by law</li>
          </ul>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            4. Data Security
          </Typography>
          <Typography paragraph>
            We implement appropriate technical and organizational measures to
            protect your personal information. However, no method of transmission
            over the internet is 100% secure.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            5. Cookies and Tracking
          </Typography>
          <Typography paragraph>
            We use cookies and similar technologies to:
          </Typography>
          <ul>
            <li>Remember your preferences</li>
            <li>Understand how you use our website</li>
            <li>Provide personalized content and ads</li>
            <li>Analyze website traffic</li>
          </ul>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            6. Your Rights
          </Typography>
          <Typography paragraph>
            You have the right to:
          </Typography>
          <ul>
            <li>Access your personal information</li>
            <li>Correct inaccurate information</li>
            <li>Request deletion of your information</li>
            <li>Opt-out of marketing communications</li>
            <li>Object to processing of your information</li>
          </ul>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            7. Children's Privacy
          </Typography>
          <Typography paragraph>
            Our services are not intended for children under 13. We do not
            knowingly collect information from children under 13.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            8. Changes to Privacy Policy
          </Typography>
          <Typography paragraph>
            We may update this privacy policy from time to time. We will notify
            you of any changes by posting the new policy on this page.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h5" gutterBottom>
            9. Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions about this privacy policy, please contact us
            at:
          </Typography>
          <Typography>Email: privacy@provisionstore.com</Typography>
          <Typography>Phone: +233 24 123 4567</Typography>
          <Typography>Address: 123 Main Street, Accra, Ghana</Typography>
        </Box>
      </Paper>

      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          Last updated: August 2023
        </Typography>
      </Box>
    </Container>
  );
};

export default Privacy; 