import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Chip,
  Alert,
  CircularProgress,
  Divider,
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { getImageUrl } from '../utils/imageUtils';
import api from '../utils/api';

const ActiveOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchActiveOrders();
  }, []);

  const fetchActiveOrders = async () => {
    try {
      setLoading(true);
      const { data } = await api.get('/orders/myorders');
      // Filter for active orders (not delivered or cancelled)
      const activeOrders = data.filter(
        order => !['delivered', 'cancelled'].includes(order.status.toLowerCase())
      );
      setOrders(activeOrders);
    } catch (err) {
      setError('Failed to load active orders');
      console.error('Error fetching orders:', err);
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'warning';
      case 'processing':
        return 'info';
      case 'shipped':
        return 'primary';
      case 'delivered':
        return 'success';
      case 'cancelled':
        return 'error';
      default:
        return 'default';
    }
  };

  const getSteps = () => {
    return ['Pending', 'Processing', 'Shipped', 'Delivered'];
  };

  const getActiveStep = (status) => {
    const steps = getSteps();
    return steps.findIndex(step => 
      step.toLowerCase() === status.toLowerCase()
    );
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Active Orders
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : orders.length === 0 ? (
        <Alert severity="info">No active orders found</Alert>
      ) : (
        <Grid container spacing={3}>
          {orders.map((order) => (
            <Grid item xs={12} key={order._id}>
              <Paper sx={{ p: 3 }}>
                {/* Order Header */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 3,
                  }}
                >
                  <Box>
                    <Typography variant="h6">
                      Order #{order._id.slice(-6)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Placed on {new Date(order.createdAt).toLocaleDateString()}
                    </Typography>
                  </Box>
                  <Box>
                    <Chip
                      label={order.status}
                      color={getStatusColor(order.status)}
                      sx={{ mr: 1 }}
                    />
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<VisibilityIcon />}
                      component={RouterLink}
                      to={`/orders/${order._id}`}
                    >
                      View Details
                    </Button>
                  </Box>
                </Box>

                {/* Order Progress */}
                <Stepper
                  activeStep={getActiveStep(order.status)}
                  alternativeLabel
                  sx={{ mb: 3 }}
                >
                  {getSteps().map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                {/* Order Summary */}
                <Box>
                  <Typography variant="subtitle2" gutterBottom>
                    Order Summary
                  </Typography>
                  <Grid container spacing={2}>
                    {order.items && order.items.map((item) => (
                      <Grid item xs={12} sm={6} md={4} key={item._id}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                          }}
                        >
                          <img
                            src={getImageUrl(item)}
                            alt={item.name}
                            style={{
                              width: 60,
                              height: 60,
                              objectFit: 'contain',
                            }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '/placeholder.jpg';
                            }}
                          />
                          <Box>
                            <Typography variant="body2" noWrap>
                              {item.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                            >
                              Qty: {item.quantity} × GHS{' '}
                              {Number(item.price).toFixed(2)}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <Divider sx={{ my: 2 }} />

                {/* Order Total */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="subtitle1">Total Amount:</Typography>
                  <Typography variant="h6">
                    GHS {order.total.toFixed(2)}
                  </Typography>
                </Box>

                {order.trackingNumber && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      Tracking Number: {order.trackingNumber}
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default ActiveOrders; 