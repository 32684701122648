import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Alert, Container, useTheme } from '@mui/material';
import ProductCard from './ProductCard';
import LoadingSpinner from './LoadingSpinner';
import api from '../utils/api';

const RecommendedProducts = ({ 
  currentProductIds = [], 
  category = null, 
  limit = 4,
  title = "Recommended Products" 
}) => {
  const theme = useTheme();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecommendedProducts = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const { data } = await api.get('/products/recommended', {
          params: {
            category,
            excludeIds: currentProductIds,
            limit
          }
        });
        
        setProducts(data);
      } catch (error) {
        console.error('Error fetching recommended products:', error);
        setError('Failed to load recommended products');
      } finally {
        setLoading(false);
      }
    };

    fetchRecommendedProducts();
  }, [currentProductIds, category, limit]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!products.length) return null;

  return (
    <Container maxWidth="lg" sx={{ 
      mb: { xs: 3, sm: 6 },
      '@media (max-width: 350px)': {
        px: 1,
      }
    }}>
      <Typography
        variant="h4"
        component="h2"
        sx={{
          mb: { xs: 2, sm: 3 },
          fontWeight: 600,
          color: theme.palette.primary.main,
          '@media (max-width: 350px)': {
            fontSize: '1.4rem',
            mb: 1.5,
          }
        }}
      >
        {title}
      </Typography>
      <Grid container spacing={{ xs: 1, sm: 3 }}>
        {products.map((product) => (
          <Grid item xs={6} sm={6} md={3} key={product._id}>
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default RecommendedProducts; 