import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Divider,
  Chip,
  Card,
  Grid,
  ListItemAvatar,
  Avatar,
  Popper,
} from '@mui/material';
import {
  Search as SearchIcon,
  History as HistoryIcon,
  TrendingUp as TrendingIcon,
  Clear as ClearIcon,
  Category as CategoryIcon,
  LocalOffer as PriceIcon,
  Label as BrandIcon,
} from '@mui/icons-material';
import api from '../utils/api';

const SearchBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const searchRef = useRef(null);
  
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);
  const [trendingSearches] = useState([
    'Groceries',
    'Beverages',
    'Snacks',
    'Dairy',
    'Produce',
    'Household',
    'Kitchen',
    'Personal Care'
  ]);

  useEffect(() => {
    const savedSearches = localStorage.getItem('recentSearches');
    if (savedSearches) {
      setRecentSearches(JSON.parse(savedSearches));
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearch = useCallback(
    debounce(async (searchQuery) => {
      if (!searchQuery.trim()) {
        setResults([]);
        return;
      }

      try {
        setLoading(true);
        const { data } = await api.get(`/products/search?query=${searchQuery}`);
        setResults(data.results || []);
      } catch (error) {
        console.error('Search error:', error);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`);
    setShowResults(false);
    setQuery('');
  };

  useEffect(() => {
    handleSearch(query);
  }, [query, handleSearch]);

  const clearRecentSearches = () => {
    setRecentSearches([]);
    localStorage.removeItem('recentSearches');
  };

  return (
    <Box 
      ref={searchRef} 
      sx={{ 
        position: 'relative',
        width: '100%',
        maxWidth: { xs: '100%', md: '600px' }
      }}
    >
      <TextField
        fullWidth
        size={isMobile ? "small" : "medium"}
        placeholder="Search products..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onFocus={() => setShowResults(true)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="action" />
            </InputAdornment>
          ),
          endAdornment: query && (
            <InputAdornment position="end">
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton size="small" onClick={() => setQuery('')}>
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
          sx: {
            bgcolor: 'background.paper',
            borderRadius: 1,
          }
        }}
      />

      {showResults && (
        <Paper
          elevation={4}
          sx={{
            position: 'fixed',
            top: isMobile ? '56px' : '64px',
            left: isMobile ? 0 : searchRef.current?.getBoundingClientRect().left,
            width: isMobile ? '100%' : searchRef.current?.offsetWidth,
            maxHeight: isMobile ? 'calc(100vh - 56px)' : '70vh',
            overflowY: 'auto',
            zIndex: theme.zIndex.modal,
            transform: 'none',
            p: 2,
          }}
        >
          {!query && (
            <>
              {recentSearches.length > 0 && (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Recent Searches
                    </Typography>
                    <IconButton size="small" onClick={clearRecentSearches}>
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <List dense sx={{ mb: 2 }}>
                    {recentSearches.map((search, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() => handleSearch(search)}
                        sx={{
                          borderRadius: 1,
                          '&:hover': {
                            bgcolor: 'action.hover',
                          }
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 40 }}>
                          <HistoryIcon color="action" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={search} />
                      </ListItem>
                    ))}
                  </List>
                  <Divider sx={{ my: 2 }} />
                </>
              )}

              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  Trending Searches
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {trendingSearches.map((search, index) => (
                    <Chip
                      key={index}
                      label={search}
                      size="small"
                      icon={<TrendingIcon />}
                      onClick={() => handleSearch(search)}
                      sx={{ 
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: 'primary.light',
                          color: 'primary.contrastText',
                        }
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </>
          )}

          {query && (
            <>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : (
                <>
                  {results.length > 0 ? (
                    <Grid container spacing={2}>
                      {results.map((result) => (
                        <Grid item xs={12} key={result._id}>
                          <Card
                            onClick={() => {
                              navigate(`/products/${result._id}`);
                              setShowResults(false);
                              setQuery('');
                            }}
                            sx={{
                              display: 'flex',
                              cursor: 'pointer',
                              '&:hover': {
                                boxShadow: theme.shadows[4],
                                transform: 'translateY(-2px)',
                                transition: 'all 0.2s ease-in-out',
                              },
                            }}
                          >
                            <Box
                              sx={{
                                width: { xs: 80, sm: 100 },
                                height: { xs: 80, sm: 100 },
                                p: 1,
                              }}
                            >
                              <Box
                                component="img"
                                src={result.thumbnail || result.images?.[0]}
                                alt={result.name}
                                sx={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                  borderRadius: 1,
                                }}
                              />
                            </Box>
                            <Box sx={{ flex: 1, p: 2 }}>
                              <Typography variant="subtitle1" component="h3" gutterBottom>
                                {result.name}
                              </Typography>
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                                <Chip
                                  size="small"
                                  icon={<CategoryIcon fontSize="small" />}
                                  label={result.category}
                                  sx={{ bgcolor: 'background.default' }}
                                />
                                <Chip
                                  size="small"
                                  icon={<PriceIcon fontSize="small" />}
                                  label={`GH₵${result.price.toFixed(2)}`}
                                  sx={{ bgcolor: 'background.default' }}
                                />
                                {result.brand && (
                                  <Chip
                                    size="small"
                                    icon={<BrandIcon fontSize="small" />}
                                    label={result.brand}
                                    sx={{ bgcolor: 'background.default' }}
                                  />
                                )}
                              </Box>
                            </Box>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Box sx={{ 
                      p: 3, 
                      textAlign: 'center',
                      bgcolor: 'background.default',
                      borderRadius: 1,
                    }}>
                      <Typography color="textSecondary" variant="body1">
                        No results found for "{query}"
                      </Typography>
                      <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                        Try different keywords or check the spelling
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Paper>
      )}
    </Box>
  );
};

export default SearchBar; 