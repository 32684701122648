import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  Chip,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  Save as SaveIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import api from '../../utils/api';
import Pagination from '../../components/Pagination';

const AdminServiceRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updateData, setUpdateData] = useState({
    status: '',
    adminNotes: '',
    totalAmount: 0,
    estimatedPrices: []
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchRequests();
  }, [currentPage]);

  const fetchRequests = async () => {
    try {
      const { data } = await api.get(`/service-requests?page=${currentPage}`);
      setRequests(data.requests);
      setTotalPages(data.totalPages);
    } catch (err) {
      setError('Failed to load service requests');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (request) => {
    setSelectedRequest(request);
    setUpdateData({
      status: request.status,
      adminNotes: request.adminNotes || '',
      totalAmount: request.totalAmount || 0,
      estimatedPrices: request.items.map(item => item.estimatedPrice || 0)
    });
    setDialogOpen(true);
  };

  const handleUpdateRequest = async () => {
    try {
      setUpdating(true);
      await api.put(`/service-requests/${selectedRequest._id}`, updateData);
      await fetchRequests();
      setDialogOpen(false);
    } catch (err) {
      setError('Failed to update request');
    } finally {
      setUpdating(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'quoted':
        return 'info';
      case 'accepted':
        return 'primary';
      case 'paid':
        return 'success';
      case 'completed':
        return 'success';
      case 'cancelled':
        return 'error';
      default:
        return 'default';
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Service Requests
      </Typography>

      <Grid container spacing={3}>
        {requests.map((request) => (
          <Grid item xs={12} key={request._id}>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography variant="h6">
                    Request #{request._id.slice(-6)}
                  </Typography>
                  <Chip
                    label={request.status.toUpperCase()}
                    color={getStatusColor(request.status)}
                  />
                </Box>

                <Typography color="text.secondary" gutterBottom>
                  Submitted by: {request.user?.name || 'Unknown'}
                  <br />
                  Date: {formatDate(request.createdAt)}
                </Typography>

                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Requested Items:
                  </Typography>
                  <Grid container spacing={2}>
                    {request.items.map((item, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography variant="subtitle2">
                              {item.name}
                            </Typography>
                            <Typography color="text.secondary">
                              Quantity: {item.quantity}
                            </Typography>
                            {item.description && (
                              <Typography variant="body2" color="text.secondary">
                                {item.description}
                              </Typography>
                            )}
                            {item.estimatedPrice > 0 && (
                              <Typography color="primary">
                                Price: GH₵{item.estimatedPrice}
                              </Typography>
                            )}
                            {item.images?.length > 0 && (
                              <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                                {item.images.map((image, imgIndex) => (
                                  <img
                                    key={imgIndex}
                                    src={image}
                                    alt={`Item ${index + 1}`}
                                    style={{
                                      width: 50,
                                      height: 50,
                                      objectFit: 'cover',
                                      borderRadius: 4
                                    }}
                                  />
                                ))}
                              </Box>
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                {request.shippingAddress && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Shipping Address:
                    </Typography>
                    <Typography variant="body2">
                      {request.shippingAddress.street}
                      <br />
                      {request.shippingAddress.city}, {request.shippingAddress.state}
                      <br />
                      {request.shippingAddress.country} {request.shippingAddress.zipCode}
                    </Typography>
                  </Box>
                )}

                {request.deliveryInstructions && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Delivery Instructions:
                    </Typography>
                    <Typography variant="body2">
                      {request.deliveryInstructions}
                    </Typography>
                  </Box>
                )}

                <Box sx={{ mt: 3 }}>
                  <Button
                    variant="contained"
                    onClick={() => handleOpenDialog(request)}
                  >
                    Update Request
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {requests.length === 0 && (
          <Grid item xs={12}>
            <Alert severity="info">
              No service requests found.
            </Alert>
          </Grid>
        )}
      </Grid>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Update Service Request
        </DialogTitle>
        <DialogContent>
          {selectedRequest && (
            <Box sx={{ pt: 2 }}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={updateData.status}
                  onChange={(e) => setUpdateData({ ...updateData, status: e.target.value })}
                  label="Status"
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="quoted">Quoted</MenuItem>
                  <MenuItem value="accepted">Accepted</MenuItem>
                  <MenuItem value="paid">Paid</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>
              </FormControl>

              <Typography variant="subtitle1" gutterBottom>
                Item Prices:
              </Typography>
              {selectedRequest.items.map((item, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography variant="body2" gutterBottom>
                    {item.name} (Qty: {item.quantity})
                  </Typography>
                  <TextField
                    fullWidth
                    type="number"
                    label="Estimated Price"
                    value={updateData.estimatedPrices[index]}
                    onChange={(e) => {
                      const newPrices = [...updateData.estimatedPrices];
                      newPrices[index] = Number(e.target.value);
                      setUpdateData({
                        ...updateData,
                        estimatedPrices: newPrices,
                        totalAmount: newPrices.reduce((sum, price, i) =>
                          sum + (price * selectedRequest.items[i].quantity), 0)
                      });
                    }}
                    InputProps={{
                      startAdornment: 'GH₵'
                    }}
                  />
                </Box>
              ))}

              <TextField
                fullWidth
                label="Total Amount"
                type="number"
                value={updateData.totalAmount}
                onChange={(e) => setUpdateData({ ...updateData, totalAmount: Number(e.target.value) })}
                sx={{ mb: 2 }}
                InputProps={{
                  startAdornment: 'GH₵'
                }}
              />

              <TextField
                fullWidth
                multiline
                rows={4}
                label="Admin Notes"
                value={updateData.adminNotes}
                onChange={(e) => setUpdateData({ ...updateData, adminNotes: e.target.value })}
                helperText="Add any notes or instructions for the customer"
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateRequest}
            disabled={updating}
            startIcon={updating ? <CircularProgress size={20} /> : <SaveIcon />}
          >
            {updating ? 'Updating...' : 'Update Request'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminServiceRequests;