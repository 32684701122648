import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  IconButton,
  Button,
  Box,
  Divider,
  Paper,
} from '@mui/material';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import {
  updateQuantityAsync,
  removeFromCartAsync,
  fetchCart
} from '../features/cart/cartSlice';
import EmptyCart from '../components/EmptyCart';

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { items = [], loading, error } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      dispatch(fetchCart());
    }
  }, [dispatch, user]);

  // Calculate totals
  const subtotal = items?.reduce((acc, item) => acc + ((item?.price || 0) * (item?.quantity || 0)), 0) || 0;
  const shipping = items?.length > 0 ? 10 : 0;
  const total = subtotal + shipping;

  const handleUpdateQuantity = (itemId, newQuantity) => {
    if (newQuantity > 0) {
      dispatch(updateQuantityAsync({ itemId, quantity: newQuantity }));
    }
  };

  const handleRemoveItem = (itemId) => {
    dispatch(removeFromCartAsync(itemId));
  };

  const handleCheckout = () => {
    navigate('/checkout');
  };

  const getImageUrl = (item) => {
    // Try to get image from populated product first
    if (item?.product?.images?.length > 0) {
      return item.product.images[0];
    }
    
    // Try product thumbnail
    if (item?.product?.thumbnail) {
      return item.product.thumbnail;
    }
    
    // Try cart item's stored image
    if (item?.image) {
      return item.image;
    }
    
    // Return placeholder if no image is found
    return '/placeholder.jpg';
  };

  if (!user) {
    return (
      <Container maxWidth="md" sx={{ py: 4, textAlign: 'center' }}>
        <Typography variant="h5" gutterBottom>
          Please log in to view your cart
        </Typography>
        <Button
          variant="contained"
          component={RouterLink}
          to="/login"
          sx={{ mt: 2 }}
        >
          Login
        </Button>
      </Container>
    );
  }

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ py: 4, textAlign: 'center' }}>
        <Typography>Loading cart...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ py: 4, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  if (!items?.length) {
    return <EmptyCart />;
  }

  return (
    <Container maxWidth="lg" sx={{ 
      py: { xs: 2, sm: 4 },
      '@media (max-width: 350px)': {
        px: 1,
      }
    }}>
      <Typography 
        variant="h4" 
        component="h1" 
        gutterBottom
        sx={{
          '@media (max-width: 350px)': {
            fontSize: '1.4rem',
            mb: 1,
          }
        }}
      >
        Shopping Cart
      </Typography>

      <Grid container spacing={{ xs: 2, sm: 3 }}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ 
            p: { xs: 1, sm: 2 },
            '@media (max-width: 350px)': {
              p: 1,
            }
          }}>
            {items.map((item) => item && (
              <Box
                key={item?._id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  py: { xs: 1, sm: 2 },
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                  '&:last-child': {
                    borderBottom: 'none',
                  },
                  '@media (max-width: 350px)': {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1,
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    '@media (max-width: 350px)': {
                      width: '100%',
                    }
                  }}
                >
                  <Box
                    component="img"
                    src={getImageUrl(item)}
                    alt={item?.product?.name || item?.name || 'Product'}
                    sx={{
                      width: { xs: 60, sm: 80 },
                      height: { xs: 60, sm: 80 },
                      objectFit: 'cover',
                      borderRadius: 1,
                      mr: 2,
                      '@media (max-width: 350px)': {
                        width: 50,
                        height: 50,
                        mr: 1,
                      }
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/placeholder.jpg';
                    }}
                  />
                  <Box sx={{ flex: 1 }}>
                    <Typography 
                      variant="subtitle1"
                      sx={{
                        '@media (max-width: 350px)': {
                          fontSize: '0.9rem',
                        }
                      }}
                    >
                      {item?.product?.name || item?.name}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{
                        '@media (max-width: 350px)': {
                          fontSize: '0.8rem',
                        }
                      }}
                    >
                      GHS {(item?.price || 0).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: 2,
                  '@media (max-width: 350px)': {
                    width: '100%',
                    justifyContent: 'space-between',
                  }
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    '@media (max-width: 350px)': {
                      '& .MuiIconButton-root': {
                        padding: '4px',
                      }
                    }
                  }}>
                    <IconButton
                      size="small"
                      onClick={() => handleUpdateQuantity(item?._id, (item?.quantity || 0) + 1)}
                    >
                      <AddIcon />
                    </IconButton>
                    <Typography 
                      sx={{ 
                        mx: 1,
                        '@media (max-width: 350px)': {
                          fontSize: '0.9rem',
                          mx: 0.5,
                        }
                      }}
                    >
                      {item?.quantity || 0}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => handleUpdateQuantity(item?._id, (item?.quantity || 0) - 1)}
                      disabled={(item?.quantity || 0) <= 1}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Box>

                  <IconButton
                    onClick={() => handleRemoveItem(item?._id)}
                    color="error"
                    size="small"
                    sx={{
                      '@media (max-width: 350px)': {
                        padding: '4px',
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ 
            p: { xs: 1.5, sm: 2 },
            '@media (max-width: 350px)': {
              p: 1,
            }
          }}>
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{
                '@media (max-width: 350px)': {
                  fontSize: '1rem',
                }
              }}
            >
              Order Summary
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                mb: 1,
                '@media (max-width: 350px)': {
                  '& .MuiTypography-root': {
                    fontSize: '0.9rem',
                  }
                }
              }}>
                <Typography>Subtotal</Typography>
                <Typography>GHS {subtotal.toFixed(2)}</Typography>
              </Box>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                mb: 1,
                '@media (max-width: 350px)': {
                  '& .MuiTypography-root': {
                    fontSize: '0.9rem',
                  }
                }
              }}>
                <Typography>Delivery</Typography>
                <Typography>GHS {shipping.toFixed(2)}</Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                '@media (max-width: 350px)': {
                  '& .MuiTypography-root': {
                    fontSize: '0.9rem',
                  }
                }
              }}>
                <Typography variant="subtitle1">Total</Typography>
                <Typography variant="subtitle1">
                  GHS {total.toFixed(2)}
                </Typography>
              </Box>
            </Box>
            <Button
              variant="contained"
              fullWidth
              onClick={handleCheckout}
              sx={{
                '@media (max-width: 350px)': {
                  fontSize: '0.9rem',
                  py: 1,
                }
              }}
            >
              Proceed to Checkout
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Cart; 