import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Alert,
  CircularProgress,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
} from '@mui/material';
import api from '../utils/api';

// Add Paystack script
const addPaystackScript = () => {
  const script = document.createElement('script');
  script.src = 'https://js.paystack.co/v1/inline.js';
  script.async = true;
  document.body.appendChild(script);
};

const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { items: cartItems } = useSelector((state) => state.cart);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [orderItems, setOrderItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('paystack');
  const [isServiceOrder, setIsServiceOrder] = useState(false);
  const [serviceRequestId, setServiceRequestId] = useState(null);

  const [deliveryAddress, setDeliveryAddress] = useState({
    street: user?.address?.street || '',
    city: user?.address?.city || '',
    state: user?.address?.state || '',
    country: user?.address?.country || 'Ghana',
    zipCode: user?.address?.zipCode || '',
    phone: user?.phone || ''
  });

  // Location options
  const cityOptions = ["Hall", "Hostel", "Town"];
  
  const locationOptions = {
    "Hall": ["Casford", "ATL", "SRC", "Ogua Hall", "KNH", "VALCO", "PSI", "Super Anuation", "Adehye Hall",],
    "Hostel": [
      "LINDA", "CONNIKAP", "CONSCIOUS", "BIG BROTHER", "OGA", "AWORTWE", 
      "PIMAG", "NANA ABAKAH", "E & T", "BORNAKOW", "UNIQUE", "MAWYED",
      "DANICOM", "EXOUZIA", "STERNER", "GALAZY", "B AND C", "PLAZA",
      "EDDIE BEE", "JESUS LIVES", "EBENEZER", "HOSTEL NAME", "ALUMNI",
      "SSNIT", "EXECUTIVE", "THE NEST", "RUSSIA LODGE", "STEP", "DEAN'S",
      "ALBERTFRED", "SAMMY OTOO", "ANANSE WEB", "STANDARD ROYAL PALACE",
      "OYE INN", "NYANEBA", "WISHES", "MAPLINS", "ASEDA", "GLOBAL",
      "BLESSED CHINESE", "ROYAL PALACE", "ADORATION", "AYENSU PLAZA",
      "PEACE", "RHEMA", "GREEN", "BOSSMAN", "OCEAN VIEW", "VICTORIA TERRACE",
      "MADAM GLADYS", "AWOVALE", "ISMALIA FIE", "FRANK EMMANUEL", "OYE",
      "INNOBODRU", "AFEDZI", "FLORENCE"
    ],
    "Town": ["Amamoma", "Apewosika", "Kwaprow", "Kokoado"]
  };

  // Handle city change
  const handleCityChange = (e) => {
    const newCity = e.target.value;
    setDeliveryAddress({
      ...deliveryAddress,
      city: newCity,
      state: '' // Reset state when city changes
    });
  };

  // Add Paystack script on component mount
  useEffect(() => {
    addPaystackScript();
  }, []);

  useEffect(() => {
    // Check for service request order in localStorage
    const checkoutData = localStorage.getItem('checkoutData');
    if (checkoutData) {
      const parsedData = JSON.parse(checkoutData);
      if (parsedData.isServiceOrder) {
        setOrderItems(parsedData.items);
        setTotal(parsedData.total);
        setIsServiceOrder(true);
        setServiceRequestId(parsedData.serviceRequestId);
        return; // Exit early if service order is found
      }
    }

    // Handle regular cart checkout
    if (!cartItems || cartItems.length === 0) {
      navigate('/cart');
      return;
    }

    console.log('Initial cart items:', JSON.stringify(cartItems, null, 2)); // Debug log

    try {
      // Format cart items to include product ID
      const formattedItems = cartItems.map((item, index) => {
        console.log(`\nProcessing cart item ${index}:`, JSON.stringify(item, null, 2)); // Debug log
        
        // Get the product ID from the correct location in the cart item
        let productId;
        if (item.product?._id) {
          productId = item.product._id;
        } else if (typeof item.product === 'string') {
          productId = item.product;
        } else {
          console.error(`Invalid product data for item ${index}:`, item);
          throw new Error(`Invalid product data in cart for item ${index}`);
        }

        console.log(`Extracted product ID for item ${index}:`, productId); // Debug log

        if (!productId) {
          console.error(`Missing product ID for item ${index}:`, item);
          throw new Error(`Missing product ID in cart for item ${index}`);
        }

        const formattedItem = {
          product: productId,
          name: item.name || item.product?.name,
          quantity: item.quantity,
          price: item.price || item.product?.price,
          image: item.image || item.product?.thumbnail || item.product?.images?.[0]
        };

        // Validate the formatted item
        if (!formattedItem.name || !formattedItem.price || formattedItem.quantity <= 0) {
          console.error(`Invalid item data for item ${index}:`, formattedItem);
          throw new Error(`Invalid item data in cart for item ${index}`);
        }

        console.log(`Formatted item ${index}:`, JSON.stringify(formattedItem, null, 2)); // Debug log
        return formattedItem;
      });

      console.log('Final formatted items:', JSON.stringify(formattedItems, null, 2)); // Debug log
      setOrderItems(formattedItems);

      // Calculate total from cart items
      const calculatedTotal = cartItems.reduce((sum, item) => {
        const price = item.price || item.product?.price || 0;
        const quantity = item.quantity || 0;
        return sum + (price * quantity);
      }, 0) + 10; // Add flat delivery fee of 10
      
      console.log('Calculated total:', calculatedTotal); // Debug log
      setTotal(calculatedTotal);
    } catch (error) {
      console.error('Error formatting cart items:', error);
      setError(error.message);
      navigate('/cart');
    }
  }, [cartItems, navigate]);

  const handlePaymentSuccess = async (response) => {
    try {
      setLoading(true);
      console.log('Payment success response:', response); // Debug log

      let createdOrder;

      if (isServiceOrder) {
        // Update service request status
        await api.put(`/api/service-requests/${serviceRequestId}/payment`);
        
        // Create order for the service request
        const serviceOrderData = {
          items: orderItems.map(item => ({
            name: item.name,
            quantity: item.quantity,
            price: item.price,
            image: item.image,
            isServiceItem: true
          })),
          total: total,
          deliveryAddress: {
            street: deliveryAddress.street,
            city: deliveryAddress.city,
            state: deliveryAddress.state,
            country: deliveryAddress.country,
            zipCode: deliveryAddress.zipCode,
            phone: deliveryAddress.phone
          },
          paymentDetails: {
            method: paymentMethod,
            reference: response.reference,
            amount: total,
            status: 'paid'
          },
          serviceRequestId: serviceRequestId,
          isServiceOrder: true
        };

        console.log('Service order data:', JSON.stringify(serviceOrderData, null, 2)); // Debug log
        const { data } = await api.post('/api/orders', serviceOrderData);
        createdOrder = data;
      } else {
        // Handle regular order
        const orderData = {
          items: orderItems.map((item, index) => {
            console.log(`Processing order item ${index}:`, JSON.stringify(item, null, 2)); // Debug log
            
            if (!item.product) {
              console.error(`Missing product ID for order item ${index}:`, item);
              throw new Error(`Missing product ID in cart for item ${index}`);
            }

            return {
              product: item.product,
              name: item.name,
              quantity: item.quantity,
              price: item.price,
              image: item.image
            };
          }),
          deliveryAddress: {
            street: deliveryAddress.street,
            city: deliveryAddress.city,
            state: deliveryAddress.state,
            country: deliveryAddress.country,
            zipCode: deliveryAddress.zipCode,
            phone: deliveryAddress.phone
          },
          total: total,
          paymentDetails: {
            method: paymentMethod,
            reference: response.reference,
            amount: total,
            status: 'paid'
          }
        };

        console.log('Regular order data:', JSON.stringify(orderData, null, 2)); // Debug log
        const { data } = await api.post('/api/orders', orderData);
        createdOrder = data;
      }

      // Clear checkout data
      localStorage.removeItem('checkoutData');
      
      // Clear cart after successful order
      await api.delete('/api/cart');
      
      // Store order ID in localStorage for success page
      if (createdOrder?._id) {
        localStorage.setItem('lastOrderId', createdOrder._id);
      }
      
      // Navigate to success page
      navigate('/order-success');
    } catch (error) {
      console.error('Order creation error:', error.response?.data || error);
      setError(error.response?.data?.message || 'Failed to process order');
      setLoading(false);
    }
  };

  const handlePaystackCallback = (response) => {
    if (response.status === 'success') {
      handlePaymentSuccess({
        reference: response.reference,
        status: 'paid'
      });
    } else {
      setError('Payment failed. Please try again.');
      setLoading(false);
    }
  };

  const handlePaystackPayment = async () => {
    try {
      setLoading(true);
      setError('');

      // Check if PaystackPop is available
      if (typeof window.PaystackPop === 'undefined') {
        throw new Error('Paystack script not loaded');
      }

      // Initialize Paystack payment
      const handler = window.PaystackPop.setup({
        key: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || 'pk_test_a2f241fe026d4ce094f030fbfb4c93e602745541',
        email: user.email,
        amount: total * 100, // Convert to kobo
        currency: 'GHS',
        ref: '' + Math.floor((Math.random() * 1000000000) + 1),
        callback: function(response) {
          handlePaystackCallback(response);
        },
        onClose: function() {
          setLoading(false);
        }
      });
      handler.openIframe();
    } catch (error) {
      console.error('Payment initialization failed:', error);
      setError('Payment initialization failed. Please try again.');
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate delivery address
    if (!deliveryAddress.street || !deliveryAddress.city || !deliveryAddress.state || 
        !deliveryAddress.country || !deliveryAddress.phone) {
      setError('Please fill in all delivery address fields');
      return;
    }

    if (paymentMethod === 'paystack') {
      handlePaystackPayment();
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          {isServiceOrder ? 'Service Payment' : 'Checkout'}
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          {/* Delivery Address - Required for all orders */}
          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Delivery Address
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Street Address"
                value={deliveryAddress.street}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, street: e.target.value })}
                error={error && !deliveryAddress.street}
                helperText={error && !deliveryAddress.street ? "Street address is required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel>Location Type</FormLabel>
                <Select
                  value={deliveryAddress.city}
                  onChange={handleCityChange}
                  fullWidth
                  required
                >
                  {cityOptions.map((city) => (
                    <MenuItem key={city} value={city}>
                      {city}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel>{deliveryAddress.city || "Location"}</FormLabel>
                <Select
                  value={deliveryAddress.state}
                  onChange={(e) => setDeliveryAddress({ ...deliveryAddress, state: e.target.value })}
                  fullWidth
                  required
                  disabled={!deliveryAddress.city}
                >
                  {deliveryAddress.city && locationOptions[deliveryAddress.city].map((location) => (
                    <MenuItem key={location} value={location}>
                      {location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Country"
                value={deliveryAddress.country}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, country: e.target.value })}
                error={error && !deliveryAddress.country}
                helperText={error && !deliveryAddress.country ? "Country is required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Room Number"
                value={deliveryAddress.zipCode}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, zipCode: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Phone Number"
                value={deliveryAddress.phone}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, phone: e.target.value })}
                error={error && !deliveryAddress.phone}
                helperText={error && !deliveryAddress.phone ? "Phone number is required" : ""}
              />
            </Grid>
          </Grid>

          {/* Order Summary */}
          <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
            Order Summary
          </Typography>
          <Grid container spacing={2}>
            {orderItems.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography>
                    {item.name} x {item.quantity}
                  </Typography>
                  <Typography>
                    GH₵{(item.price * item.quantity).toFixed(2)}
                  </Typography>
                </Box>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Delivery Fee</Typography>
                <Typography variant="h6">
                  GH₵ 10
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Total</Typography>
                <Typography variant="h6">
                  GH₵{total.toFixed(2)}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* Payment Method */}
          <FormControl component="fieldset" sx={{ mt: 3 }}>
            <FormLabel component="legend">Payment Method</FormLabel>
            <RadioGroup
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <FormControlLabel
                value="paystack"
                control={<Radio />}
                label="Pay with Paystack"
              />
            </RadioGroup>
          </FormControl>

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Place Order'}
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default Checkout;