import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  Rating,
  Divider,
  Paper,
  IconButton,
  TextField,
  Alert,
  Snackbar,
  useTheme,
  Tabs,
  Tab,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import {
  AddShoppingCart as AddCartIcon,
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  NavigateBefore as PrevIcon,
  NavigateNext as NextIcon,
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { addToCartAsync } from '../features/cart/cartSlice';
import { addToWishlist, removeFromWishlist } from '../features/wishlist/wishlistSlice';
import api from '../utils/api';
import LoadingSpinner from '../components/LoadingSpinner';
import RecommendedProducts from '../components/RecommendedProducts';
import { format } from 'date-fns';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`product-tabpanel-${index}`}
      aria-labelledby={`product-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageError, setImageError] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [tabValue, setTabValue] = useState(0);

  const wishlist = useSelector((state) => state.wishlist) || { items: [] };
  const cart = useSelector((state) => state.cart) || { items: [] };
  const isInWishlist = wishlist.items.some((item) => item._id === id);
  const cartItem = cart.items.find((item) => item._id === id);
  const availableStock = product ? product.stock - (cartItem?.quantity || 0) : 0;

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/products/${id}`);
        setProduct(data);
      } catch (error) {
        setError(error.response?.data?.message || 'Error fetching product');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleQuantityChange = (event) => {
    const value = parseInt(event.target.value);
    if (value > 0 && value <= availableStock) {
      setQuantity(value);
    }
  };

  const handleAddToCart = () => {
    if (availableStock >= quantity) {
      dispatch(addToCartAsync({ productId: product._id, quantity }));
      setSnackbar({
        open: true,
        message: 'Added to cart successfully',
        severity: 'success',
      });
    }
  };

  const handleWishlistToggle = () => {
    if (isInWishlist) {
      dispatch(removeFromWishlist(id));
      setSnackbar({
        open: true,
        message: 'Removed from wishlist',
        severity: 'info',
      });
    } else {
      dispatch(addToWishlist(product));
      setSnackbar({
        open: true,
        message: 'Added to wishlist',
        severity: 'success',
      });
    }
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === (product.images.length - 1) ? 0 : prev + 1
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? product.images.length - 1 : prev - 1
    );
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const getImageUrl = (imageUrl) => {
    if (imageUrl?.startsWith('http')) {
      return imageUrl;
    }
    return '/placeholder.jpg';
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!product) return <Alert severity="error">Product not found</Alert>;

  const currentImage = imageError ? null : (product.images && product.images.length > 0)
    ? getImageUrl(product.images[currentImageIndex])
    : getImageUrl(product.thumbnailUrl);

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 2, sm: 4 } }}>
      <Grid container spacing={{ xs: 2, sm: 4 }}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={2}
            sx={{
              position: 'relative',
              height: { xs: 300, sm: 400 },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'background.default',
              overflow: 'hidden',
              '@media (max-width: 350px)': {
                height: 250,
              }
            }}
          >
            <AnimatePresence mode="wait">
              {imageError ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <Typography color="text.secondary">
                    Image not available
                  </Typography>
                </Box>
              ) : (
                <motion.img
                  key={currentImage}
                  src={getImageUrl(currentImage)}
                  alt={product.name}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                    padding: 16,
                  }}
                  onError={() => setImageError(true)}
                />
              )}
            </AnimatePresence>

            {product.images && product.images.length > 1 && (
              <>
                <IconButton
                  onClick={handlePrevImage}
                  sx={{
                    position: 'absolute',
                    left: 8,
                    bgcolor: 'background.paper',
                    '&:hover': { bgcolor: 'background.default' },
                  }}
                >
                  <PrevIcon />
                </IconButton>
                <IconButton
                  onClick={handleNextImage}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    bgcolor: 'background.paper',
                    '&:hover': { bgcolor: 'background.default' },
                  }}
                >
                  <NextIcon />
                </IconButton>
              </>
            )}
          </Paper>

          {product.images && product.images.length > 1 && (
            <Box sx={{ 
              mt: 2, 
              display: 'flex', 
              gap: 1, 
              overflowX: 'auto', 
              pb: 1,
              '@media (max-width: 350px)': {
                gap: 0.5,
                mt: 1,
              }
            }}>
              {product.images.map((imageUrl, index) => (
                <Box
                  key={index}
                  component={motion.div}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  sx={{
                    width: { xs: 60, sm: 80 },
                    height: { xs: 60, sm: 80 },
                    flexShrink: 0,
                    cursor: 'pointer',
                    border: index === currentImageIndex ? `2px solid ${theme.palette.primary.main}` : 'none',
                    '@media (max-width: 350px)': {
                      width: 50,
                      height: 50,
                    }
                  }}
                  onClick={() => setCurrentImageIndex(index)}
                >
                  <img
                    src={getImageUrl(imageUrl)}
                    alt={`Product view ${index + 1}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ 
            pl: { xs: 0, md: 2 },
            '@media (max-width: 350px)': {
              px: 1,
            }
          }}>
            <Typography 
              variant="h4" 
              component="h1" 
              gutterBottom
              sx={{
                '@media (max-width: 350px)': {
                  fontSize: '1.5rem',
                  mb: 1,
                }
              }}
            >
              {product.name}
            </Typography>

            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mb: 2,
              '@media (max-width: 350px)': {
                mb: 1,
                flexWrap: 'wrap',
                gap: 1,
              }
            }}>
              <Rating value={product.rating} readOnly precision={0.5} />
              <Typography 
                variant="body2" 
                color="text.secondary" 
                sx={{ 
                  ml: 1,
                  '@media (max-width: 350px)': {
                    fontSize: '0.8rem',
                  }
                }}
              >
                ({product.numReviews} reviews)
              </Typography>
            </Box>

            <Typography 
              variant="h5" 
              color="primary" 
              sx={{ 
                mb: 2,
                '@media (max-width: 350px)': {
                  fontSize: '1.2rem',
                  mb: 1,
                }
              }}
            >
              GHS {product.price.toFixed(2)}
            </Typography>

            <Typography 
              variant="body1" 
              paragraph
              sx={{
                '@media (max-width: 350px)': {
                  fontSize: '0.9rem',
                  mb: 1,
                }
              }}
            >
              {product.description}
            </Typography>

            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 2, 
              mb: 3,
              '@media (max-width: 350px)': {
                flexDirection: 'column',
                alignItems: 'stretch',
                gap: 1,
                mb: 2,
              }
            }}>
              <TextField
                type="number"
                label="Quantity"
                value={quantity}
                onChange={handleQuantityChange}
                inputProps={{ min: 1, max: availableStock }}
                sx={{
                  width: { xs: '100%', sm: 100 },
                  '@media (max-width: 350px)': {
                    '& .MuiInputLabel-root': {
                      fontSize: '0.9rem',
                    },
                    '& .MuiInputBase-input': {
                      fontSize: '0.9rem',
                      padding: '8px 12px',
                    },
                  }
                }}
              />
              <Button
                variant="contained"
                startIcon={<AddCartIcon />}
                onClick={handleAddToCart}
                disabled={availableStock === 0}
                fullWidth
                sx={{
                  '@media (max-width: 350px)': {
                    fontSize: '0.9rem',
                    py: 1,
                  }
                }}
              >
                Add to Cart
              </Button>
              <IconButton
                onClick={handleWishlistToggle}
                color={isInWishlist ? 'primary' : 'default'}
                sx={{
                  '@media (max-width: 350px)': {
                    alignSelf: 'center',
                  }
                }}
              >
                {isInWishlist ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{
                '@media (max-width: 350px)': {
                  fontSize: '0.8rem',
                }
              }}
            >
              Stock: {availableStock} items available
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Product Tabs */}
      <Box sx={{ 
        width: '100%',
        '@media (max-width: 350px)': {
          mt: 2,
        }
      }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            '@media (max-width: 350px)': {
              '& .MuiTab-root': {
                fontSize: '0.8rem',
                minWidth: 'auto',
                px: 1,
              }
            }
          }}
        >
          <Tab label="Description" />
          <Tab label="Specifications" />
          <Tab label="Reviews" />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <div dangerouslySetInnerHTML={{ __html: product?.description || 'No description available.' }} />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <div dangerouslySetInnerHTML={{ __html: product?.specifications || 'No specifications available.' }} />
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <Box>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Customer Reviews
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Rating value={product?.rating || 0} precision={0.5} readOnly />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  ({product?.numReviews || 0} reviews)
                </Typography>
              </Box>
            </Box>

            <List>
              {product?.reviews?.length > 0 ? (
                product.reviews.map((review) => (
                  <ListItem
                    key={review._id}
                    alignItems="flex-start"
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'divider',
                      '&:last-child': { borderBottom: 0 }
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>{review.name[0]}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="subtitle1">{review.name}</Typography>
                          <Typography variant="caption" color="text.secondary">
                            {format(new Date(review.createdAt), 'MMM dd, yyyy')}
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <>
                          <Rating value={review.rating} size="small" readOnly sx={{ my: 1 }} />
                          <Typography variant="body2" color="text.primary">
                            {review.comment}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No reviews yet. Be the first to review this product!
                </Typography>
              )}
            </List>
          </Box>
        </TabPanel>
      </Box>

      {/* Recommended Products */}
      <Box sx={{ 
        mt: 4,
        '@media (max-width: 350px)': {
          mt: 2,
        }
      }}>
        <RecommendedProducts 
          category={product?.category} 
          currentProductIds={[product?._id]} 
        />
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ProductDetails; 