import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Paper,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  CircularProgress,
  Pagination,
  Checkbox,
  Tooltip,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Image as ImageIcon,
  DeleteSweep as DeleteSweepIcon,
} from '@mui/icons-material';
import api from '../../utils/api';
import { getImageUrl } from '../../utils/imageUtils';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(12);
  const fileInputRef = useRef(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [previewThumbnail, setPreviewThumbnail] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const initialProductState = {
    name: '',
    description: '',
    brand: '',
    price: '',
    category: '',
    stock: '',
    discount: '',
  };

  const [productData, setProductData] = useState(initialProductState);

  

  const categories = [
    { id: 'Groceries', label: 'Groceries', icon: '🛒' },
    { id: 'Beverages', label: 'Beverages', icon: '🥤' },
    { id: 'Snacks', label: 'Snacks', icon: '🍿' },
    { id: 'Dairy', label: 'Dairy', icon: '🥛' },
    { id: 'Produce', label: 'Produce', icon: '🥬' },
    { id: 'Household', label: 'Household', icon: '🏠' },
    { id: 'Kitchen', label: 'Kitchen', icon: '🍳' },
    { id: 'Personal Care', label: 'Personal Care', icon: '🧴' }
  ];

  useEffect(() => {
    fetchProducts();
  }, [page]);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/products?page=${page}&limit=${limit}`);
      setProducts(data.products);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Failed to fetch products');
    } finally {
      setLoading(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    setEditMode(false);
    setSelectedProduct(null);
    setProductData(initialProductState);
    setSelectedFiles([]);
    setThumbnailFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
    setSuccess(false);
    setEditMode(false);
    setSelectedProduct(null);
    setProductData(initialProductState);
    setSelectedFiles([]);
    setThumbnailFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleEdit = (product) => {
    setEditMode(true);
    setSelectedProduct(product);
    setProductData({
      name: product.name,
      description: product.description,
      brand: product.brand,
      price: product.price,
      category: product.category,
      stock: product.stock,
      discount: product.discount || '',
    });
    setOpen(true);
  };

  const handleDelete = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await api.delete(`/products/${productId}`);
        fetchProducts();
      } catch (error) {
        console.error('Error deleting product:', error);
        setError('Failed to delete product');
      }
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      console.log('Selected files:', files);
      setSelectedFiles(files);
    }
  };

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setThumbnailFile(file);
      setPreviewThumbnail(URL.createObjectURL(file));
    }
  };

  const handleImagesChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      setSelectedFiles(files);
      setPreviewImages(files.map(file => URL.createObjectURL(file)));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const formData = new FormData();
      
      // Add the thumbnail
      if (thumbnailFile) {
        formData.append('thumbnail', thumbnailFile);
      }

      // Add product images
      if (selectedFiles.length > 0) {
        selectedFiles.forEach(file => {
          formData.append('images', file);
        });
      }

      // Add other product data
      Object.keys(productData).forEach(key => {
        if (productData[key] !== '') {
          formData.append(key, productData[key]);
        }
      });

      // Log formData contents
      console.log('Form data being sent:');
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      if (editMode && selectedProduct) {
        await api.put(`/products/${selectedProduct._id}`, formData, config);
      } else {
        await api.post('/products', formData, config);
      }

      // Reset form and refresh product list
      setProductData(initialProductState);
      setSelectedFiles([]);
      setThumbnailFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      setEditMode(false);
      setSelectedProduct(null);
      await fetchProducts();
      setSuccess(true);
      handleClose();
    } catch (error) {
      console.error('Error saving product:', error);
      setError(error.response?.data?.message || error.message || 'Failed to save product');
    } finally {
      setLoading(false);
    }
  };

  const handleBulkDelete = async () => {
    if (selectedProducts.length === 0) {
      setError('Please select products to delete');
      return;
    }

    try {
      setLoading(true);
      await api.delete('/api/products/bulk', {
        data: { ids: selectedProducts }
      });
      
      setSuccess('Products deleted successfully');
      setSelectedProducts([]);
      fetchProducts();
    } catch (error) {
      console.error('Error deleting products:', error);
      setError(error.response?.data?.message || 'Failed to delete products');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectProduct = (productId) => {
    setSelectedProducts(prev => {
      if (prev.includes(productId)) {
        return prev.filter(id => id !== productId);
      } else {
        return [...prev, productId];
      }
    });
  };

  const handleSelectAllProducts = () => {
    if (selectedProducts.length === products.length) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(products.map(p => p._id));
    }
  };

  useEffect(() => {
    return () => {
      if (previewThumbnail) URL.revokeObjectURL(previewThumbnail);
      previewImages.forEach(url => URL.revokeObjectURL(url));
    };
  }, [previewThumbnail, previewImages]);

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4">Products</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {selectedProducts.length > 0 && (
            <Tooltip title="Delete selected products">
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteSweepIcon />}
                onClick={handleBulkDelete}
              >
                Delete Selected ({selectedProducts.length})
              </Button>
            </Tooltip>
          )}
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            Add Product
          </Button>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Product {editMode ? 'updated' : 'created'} successfully!
        </Alert>
      )}

      <Grid container spacing={3}>
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={4} key={product._id}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative',
              }}
            >
              <Box sx={{ position: 'absolute', top: 8, left: 8, zIndex: 1 }}>
                <Checkbox
                  checked={selectedProducts.includes(product._id)}
                  onChange={() => handleSelectProduct(product._id)}
                  color="primary"
                />
              </Box>
              <Box
                sx={{
                  height: 200,
                  mb: 2,
                  position: 'relative',
                  backgroundColor: 'grey.100',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                }}
              >
                {product.thumbnail || product.images?.length > 0 ? (
                  <img
                    src={getImageUrl(product)}
                    alt={product.name}
                    style={{
                      maxHeight: '100%',
                      maxWidth: '100%',
                      objectFit: 'contain',
                    }}
                  />
                ) : (
                  <ImageIcon sx={{ fontSize: 60, color: 'grey.400' }} />
                )}
              </Box>
              <Typography variant="h6" gutterBottom>
                {product.name}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  mb: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {product.description}
              </Typography>
              <Box sx={{ mt: 'auto' }}>
                <Typography variant="h6" color="primary">
                  GHS {product.price.toFixed(2)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Stock: {product.stock}
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                  <IconButton
                    color="primary"
                    onClick={() => handleEdit(product)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDelete(product._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {totalPages > 1 && (
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(e, value) => setPage(value)}
            color="primary"
            size="large"
          />
        </Box>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {editMode ? 'Edit Product' : 'Add New Product'}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Product Name"
                  name="name"
                  value={productData.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Brand"
                  name="brand"
                  value={productData.brand}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={3}
                  label="Description"
                  name="description"
                  value={productData.description}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  type="number"
                  label="Price"
                  name="price"
                  value={productData.price}
                  onChange={handleInputChange}
                  inputProps={{ min: 0, step: 0.01 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  type="number"
                  label="Stock"
                  name="stock"
                  value={productData.stock}
                  onChange={handleInputChange}
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Category</InputLabel>
                  <Select
                    name="category"
                    value={productData.category}
                    onChange={handleInputChange}
                    label="Category"
                  >
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.icon} {category.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Discount (%)"
                  name="discount"
                  value={productData.discount}
                  onChange={handleInputChange}
                  inputProps={{ min: 0, max: 100 }}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  type="file"
                  name="thumbnail"
                  onChange={handleThumbnailChange}
                  style={{ display: 'none' }}
                  id="thumbnail-input"
                />
                <label htmlFor="thumbnail-input">
                  <Button
                    variant="outlined"
                    component="span"
                    startIcon={<ImageIcon />}
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    {thumbnailFile ? thumbnailFile.name : 'Upload Thumbnail'}
                  </Button>
                </label>
                {previewThumbnail && (
                  <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Thumbnail Preview:
                    </Typography>
                    <Box
                      sx={{
                        width: '100%',
                        height: 200,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: 'background.default',
                        borderRadius: 1,
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        src={previewThumbnail}
                        alt="Thumbnail preview"
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </Box>
                  </Box>
                )}

                <input
                  accept="image/*"
                  type="file"
                  name="images"
                  onChange={handleImagesChange}
                  multiple
                  style={{ display: 'none' }}
                  id="images-input"
                />
                <label htmlFor="images-input">
                  <Button
                    variant="outlined"
                    component="span"
                    startIcon={<ImageIcon />}
                    fullWidth
                  >
                    {selectedFiles.length > 0
                      ? `${selectedFiles.length} files selected`
                      : 'Upload Product Images'}
                  </Button>
                </label>
                {previewImages.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Product Images Preview:
                    </Typography>
                    <Grid container spacing={1}>
                      {previewImages.map((preview, index) => (
                        <Grid item xs={4} key={index}>
                          <Box
                            sx={{
                              width: '100%',
                              paddingTop: '100%',
                              position: 'relative',
                              bgcolor: 'background.default',
                              borderRadius: 1,
                              overflow: 'hidden',
                            }}
                          >
                            <img
                              src={preview}
                              alt={`Preview ${index + 1}`}
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                padding: 8,
                              }}
                            />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {loading
                ? 'Saving...'
                : editMode
                ? 'Update Product'
                : 'Create Product'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default Products; 