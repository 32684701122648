import { createTheme } from '@mui/material';

export const createAppTheme = (mode) => {
  // Define custom colors
  const colors = {
    background: '#f7f6f7',
    primary: '#14ba85',
    secondary: '#fab946',
    accent: '#b0e4f4',
  };

  return createTheme({
    palette: {
      mode,
      primary: {
        main: colors.primary,
        light: '#4dcba3',
        dark: '#0e8860',
        contrastText: '#ffffff',
      },
      secondary: {
        main: colors.secondary,
        light: '#fbc76e',
        dark: '#af8131',
        contrastText: '#000000',
      },
      background: {
        default: colors.background,
        paper: mode === 'light' ? '#ffffff' : '#1e1e1e',
      },
      accent: {
        main: colors.accent,
        light: '#c8edf7',
        dark: '#7ba0ab',
      },
      text: {
        primary: mode === 'light' ? '#2d2d2d' : '#ffffff',
        secondary: mode === 'light' ? '#666666' : '#b3b3b3',
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      h1: {
        fontSize: '2.5rem',
        fontWeight: 600,
        color: mode === 'light' ? '#2d2d2d' : '#ffffff',
      },
      h2: {
        fontSize: '2rem',
        fontWeight: 600,
        color: mode === 'light' ? '#2d2d2d' : '#ffffff',
      },
      h3: {
        fontSize: '1.75rem',
        fontWeight: 600,
        color: mode === 'light' ? '#2d2d2d' : '#ffffff',
      },
      h4: {
        fontSize: '1.5rem',
        fontWeight: 600,
        color: mode === 'light' ? '#2d2d2d' : '#ffffff',
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 600,
        color: mode === 'light' ? '#2d2d2d' : '#ffffff',
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 600,
        color: mode === 'light' ? '#2d2d2d' : '#ffffff',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: 8,
            fontWeight: 500,
          },
          containedPrimary: {
            backgroundColor: colors.primary,
            '&:hover': {
              backgroundColor: '#0e8860',
            },
          },
          containedSecondary: {
            backgroundColor: colors.secondary,
            color: '#000000',
            '&:hover': {
              backgroundColor: '#af8131',
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 12,
            backgroundColor: mode === 'light' ? '#ffffff' : '#1e1e1e',
            boxShadow: mode === 'light'
              ? '0 4px 6px rgba(0, 0, 0, 0.1)'
              : '0 4px 6px rgba(0, 0, 0, 0.5)',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: colors.primary,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: colors.primary,
              },
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: colors.primary,
            '&.Mui-checked': {
              color: colors.primary,
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: colors.primary,
            '&.Mui-checked': {
              color: colors.primary,
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: colors.primary,
              '& + .MuiSwitch-track': {
                backgroundColor: colors.primary,
              },
            },
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            backgroundColor: colors.accent,
            '& .MuiLinearProgress-bar': {
              backgroundColor: colors.primary,
            },
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: colors.primary,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            '&.MuiChip-colorPrimary': {
              backgroundColor: colors.primary,
            },
            '&.MuiChip-colorSecondary': {
              backgroundColor: colors.secondary,
              color: '#000000',
            },
          },
        },
      },
    },
  });
}; 