import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  Badge,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Stack,
} from '@mui/material';
import {
  Menu as MenuIcon,
  AccountCircle,
  ShoppingCart as CartIcon,
  Person as PersonIcon,
  ExitToApp as LogoutIcon,
  Settings as SettingsIcon,
  Dashboard as DashboardIcon,
  ListAlt as OrdersIcon,
  Build as ServiceIcon,
} from '@mui/icons-material';
import { logout } from '../features/auth/authSlice';
import { clearCartAsync } from '../features/cart/cartSlice';
import { fetchNotifications, markAsRead, addNotification } from '../features/notifications/notificationSlice';
import { useSocket } from '../context/SocketContext';
import SearchBar from './SearchBar';
import NotificationIcon from './NotificationIcon';

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const socket = useSocket();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { user, userInfo } = useSelector((state) => state.auth);
  const { items } = useSelector((state) => state.cart);
  const { notifications, unreadCount } = useSelector((state) => state.notifications);

  useEffect(() => {
    if (user) {
      console.log('Fetching notifications...');
      dispatch(fetchNotifications({ limit: 10 }));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (socket && user) {
      console.log('Setting up socket notification listener...');
      
      socket.on('notification', (newNotification) => {
        console.log('New notification received:', newNotification);
        dispatch(addNotification(newNotification));
        dispatch(fetchNotifications({ limit: 10 }));
      });

      socket.on('orderStatusUpdated', ({ orderId, status, image }) => {
        console.log('Order status updated:', { orderId, status });
        dispatch(fetchNotifications({ limit: 10 }));
      });

      socket.on('serviceRequestUpdate', ({ serviceRequestId, status, image }) => {
        console.log('Service request updated:', { serviceRequestId, status });
        dispatch(fetchNotifications({ limit: 10 }));
      });

      return () => {
        console.log('Cleaning up socket notification listeners...');
        socket.off('notification');
        socket.off('orderStatusUpdated');
        socket.off('serviceRequestUpdate');
      };
    }
  }, [socket, user, dispatch]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearCartAsync());
    navigate('/');
    handleCloseUserMenu();
  };

  const handleNotificationClick = async (notification) => {
    console.log('Notification clicked:', notification);
    if (!notification.read) {
      await dispatch(markAsRead([notification._id]));
    }
    setAnchorElNotifications(null);
    if (notification.link) {
      navigate(notification.link);
    }
  };

  const menuItems = [
    { text: 'Products', path: '/products' },
    { text: 'About', path: '/about' },
    { text: 'Contact', path: '/contact' },
    ...(user ? [{ text: 'Special Service', path: '/service-requests/new' }] : []),
  ];

  const userMenuItems = user ? [
    ...(user.role === 'admin' ? [
      { text: 'Dashboard', path: '/admin',  icon: <DashboardIcon /> },
      { text: 'Settings', path: '/admin/settings', icon: <SettingsIcon /> },
    ] : [
      { text: 'Profile', path: '/profile', icon: <PersonIcon /> },
      { text: 'My Orders', path: '/orders', icon: <OrdersIcon /> },
      { text: 'My Special Requests', path: '/service-requests/my-requests', icon: <ServiceIcon /> },
    ]),
    { text: 'Logout', action: handleLogout, icon: <LogoutIcon /> },
  ] : [];

  const renderMobileMenu = () => (
    <Drawer
      anchor="left"
      open={mobileMenuOpen}
      onClose={() => setMobileMenuOpen(false)}
    >
      <Box sx={{ width: 250 }} role="presentation">
        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              component={RouterLink}
              to={item.path}
              onClick={() => setMobileMenuOpen(false)}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {user ? (
            userMenuItems.map((item) => (
              <ListItem
                button
                key={item.text}
                onClick={() => {
                  if (item.action) {
                    item.action();
                  } else {
                    navigate(item.path);
                  }
                  setMobileMenuOpen(false);
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))
          ) : (
            <ListItem
              button
              component={RouterLink}
              to="/login"
              onClick={() => setMobileMenuOpen(false)}
            >
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary="Login" />
            </ListItem>
          )}
        </List>
      </Box>
    </Drawer>
  );

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Mobile Menu Icon */}
          {isMobile && (
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => setMobileMenuOpen(true)}
              color="inherit"
              sx={{ mr: 1 }}
            >
              <MenuIcon />
            </IconButton>
          )}

          {/* Logo */}
          <Typography
            variant="h6"
            noWrap
            component={RouterLink}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'flex' },
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
              fontSize: { xs: '1.1rem', md: '1.25rem' }
            }}
          >
            JOTH LOGISTICS
          </Typography>

          {/* Search Bar */}
          <Box sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            justifyContent: 'center',
            mx: { xs: 0, md: 4 },
            order: { xs: 2, md: 1 },
            position: 'static'
          }}>
            <SearchBar />
          </Box>

          {/* Desktop Menu */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, order: { xs: 3, md: 2 } }}>
            {menuItems.map((item) => (
              <Button
                key={item.text}
                component={RouterLink}
                to={item.path}
                sx={{ 
                  color: 'white',
                  mx: 0.5,
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                  }
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>

          {/* Cart and User Menu */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 2,
            ml: 'auto'
          }}>
            {/* Notification Icon */}
            {user && (
              <Stack direction="row" spacing={1} alignItems="center">
                <NotificationIcon />
                <IconButton 
                  color="inherit" 
                  component={RouterLink} 
                  to="/cart"
                  sx={{
                    transition: 'transform 0.2s',
                    '&:hover': { transform: 'scale(1.1)' },
                  }}
                >
                  <Badge badgeContent={items?.length || 0} color="error">
                    <CartIcon />
                  </Badge>
                </IconButton>
              </Stack>
            )}
            
            {/* User Menu - Only show on desktop */}
            {user ? (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} 
                  sx={{
                  p: 0.5,
                  ml: 1,
                  border: '2px solid',
                  borderColor: 'white',
                  borderRadius: '50%',}}>
              
                <Avatar
                  sx={{
                    width: { xs: 28, sm: 32 },
                    height: { xs: 28, sm: 32 },
                    bgcolor: 'white',
                    color: 'primary.main',
                    fontSize: { xs: '0.9rem', sm: '1rem' },
                  }}
                    >
                      {user.name.charAt(0).toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  PaperProps={{
                    elevation: 3,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
                      mt: 1.5,
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                >
                  {userMenuItems.map((item) => (
                    <MenuItem
                      key={item.text}
                      onClick={() => {
                        if (item.action) {
                          item.action();
                        } else {
                          navigate(item.path);
                          handleCloseUserMenu();
                        }
                      }}
                      sx={{
                        py: 1,
                        px: 2.5,
                        '&:hover': {
                          backgroundColor: 'action.hover'
                        }
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 36 }}>{item.icon}</ListItemIcon>
                      <Typography variant="body2">{item.text}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            ) : (
              <Button
                component={RouterLink}
                to="/login"
                color="inherit"
                sx={{ 
                  display: { xs: 'none', md: 'flex' },
                  ml: 2,
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                  }
                }}
              >
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>

      {/* Notifications Menu */}
      <Menu
        anchorEl={anchorElNotifications}
        open={Boolean(anchorElNotifications)}
        onClose={() => setAnchorElNotifications(null)}
        PaperProps={{
          elevation: 3,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
            mt: 1.5,
            width: 360,
            maxHeight: 480,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
          <Typography variant="h6">Notifications</Typography>
        </Box>
        {notifications && notifications.length > 0 ? (
          <>
            {notifications.map((notification) => (
              <MenuItem
                key={notification._id}
                onClick={() => handleNotificationClick(notification)}
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: 1,
                  borderColor: 'divider',
                  backgroundColor: notification.read ? 'inherit' : 'action.hover',
                  '&:hover': {
                    backgroundColor: 'action.hover'
                  }
                }}
              >
                <ListItemText
                  primary={notification.title}
                  secondary={notification.message}
                  primaryTypographyProps={{ 
                    variant: 'subtitle2',
                    sx: { fontWeight: notification.read ? 400 : 600 }
                  }}
                  secondaryTypographyProps={{ variant: 'body2' }}
                />
              </MenuItem>
            ))}
            <Box sx={{ p: 1, textAlign: 'center' }}>
              <Button
                fullWidth
                onClick={() => {
                  navigate('/notifications');
                  setAnchorElNotifications(null);
                }}
              >
                View All
              </Button>
            </Box>
          </>
        ) : (
          <Box sx={{ p: 2, textAlign: 'center' }}>
            <Typography color="text.secondary">No notifications</Typography>
          </Box>
        )}
      </Menu>

      {/* Mobile Drawer */}
      {renderMobileMenu()}
    </AppBar>
  );
};

export default Header;