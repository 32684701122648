import React, { useState } from 'react';
import {
  Badge,
  IconButton,
  Popover,
  Box,
  Typography,
  List,
  Button,
  styled,
  useTheme,
} from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NotificationItem from './NotificationItem';

const PopoverContent = styled(Box)(({ theme }) => ({
  width: 360,
  maxHeight: 480,
  overflow: 'auto',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[8],
  borderRadius: theme.shape.borderRadius,
  '&::-webkit-scrollbar': {
    width: 6,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,0.2)',
    borderRadius: 3,
  },
}));

const SectionTitle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 2, 1, 2),
  display: 'flex',
  alignItems: 'center',
  '& .count': {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '0 8px',
    borderRadius: 12,
    fontSize: '0.75rem',
    height: 20,
    display: 'flex',
    alignItems: 'center',
  },
}));

const ViewAllButton = styled(Button)(({ theme }) => ({
  width: '100%',
  justifyContent: 'center',
  padding: theme.spacing(1.5),
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
}));

const NotificationIcon = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const { notifications, unreadCount } = useSelector(state => state.notifications);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (notification) => {
    if (notification.link) {
      navigate(notification.link);
      handleClose();
    }
  };

  const handleViewAll = () => {
    navigate('/profile');
    handleClose();
  };

  const newNotifications = notifications.filter(n => 
    (new Date() - new Date(n.createdAt)) < (24 * 60 * 60 * 1000)
  );
  
  const earlierNotifications = notifications.filter(n => 
    (new Date() - new Date(n.createdAt)) >= (24 * 60 * 60 * 1000)
  );

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleClick}
        size="large"
        sx={{
          transition: 'transform 0.2s',
          '&:hover': { transform: 'scale(1.1)' },
        }}
      >
        <Badge 
          badgeContent={unreadCount} 
          color="error"
          sx={{
            '& .MuiBadge-badge': {
              right: -3,
              top: 3,
              border: `2px solid ${theme.palette.background.paper}`,
              padding: '0 4px',
            },
          }}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          elevation: 8,
          sx: {
            mt: 1,
            overflow: 'visible',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <PopoverContent>
          <Typography 
            variant="h6" 
            sx={{ 
              p: 2, 
              borderBottom: 1, 
              borderColor: 'divider',
              fontWeight: 600,
            }}
          >
            Notifications
          </Typography>

          {newNotifications.length > 0 && (
            <>
              <SectionTitle>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>New</Typography>
                <span className="count">{newNotifications.length}</span>
              </SectionTitle>
              <List disablePadding>
                {newNotifications.map((notification) => (
                  <NotificationItem
                    key={notification._id}
                    notification={notification}
                    onClick={() => handleNotificationClick(notification)}
                  />
                ))}
              </List>
            </>
          )}

          {earlierNotifications.length > 0 && (
            <>
              <SectionTitle>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>Earlier</Typography>
                <span className="count">{earlierNotifications.length}</span>
              </SectionTitle>
              <List disablePadding>
                {earlierNotifications.map((notification) => (
                  <NotificationItem
                    key={notification._id}
                    notification={notification}
                    onClick={() => handleNotificationClick(notification)}
                  />
                ))}
              </List>
            </>
          )}

          {notifications.length === 0 ? (
            <Box sx={{ p: 3, textAlign: 'center' }}>
              <Typography color="textSecondary">
                No notifications yet
              </Typography>
            </Box>
          ) : (
            <ViewAllButton onClick={handleViewAll}>
              View All
            </ViewAllButton>
          )}
        </PopoverContent>
      </Popover>
    </>
  );
};

export default NotificationIcon;
