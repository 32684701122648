import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  IconButton,
  Grid,
  Card,
  CardMedia,
  CardActions,
  Alert,
  CircularProgress,
  Divider
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  CloudUpload as CloudUploadIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import api from '../utils/api';

const initialItemState = {
  name: '',
  description: '',
  quantity: 1,
  specifications: '',
  images: [], // For storing file objects
  imagePreviews: [] // For storing preview URLs
};

const ServiceRequest = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const [formData, setFormData] = useState({
    items: [{ ...initialItemState }],
    budget: '',
    phone: ''
  });

  const handleItemChange = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.map((item, i) => 
        i === index ? { ...item, [field]: value } : item
      )
    }));
  };

  const handleImageSelect = (index, event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    // Create preview URLs for the selected images
    const newPreviews = files.map(file => URL.createObjectURL(file));

    setFormData(prev => ({
      ...prev,
      items: prev.items.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            images: [...item.images, ...files],
            imagePreviews: [...item.imagePreviews, ...newPreviews]
          };
        }
        return item;
      })
    }));
  };

  const handleDeleteImage = (itemIndex, imageIndex) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.map((item, i) => {
        if (i === itemIndex) {
          // Revoke the URL to prevent memory leaks
          URL.revokeObjectURL(item.imagePreviews[imageIndex]);
          
          return {
            ...item,
            images: item.images.filter((_, idx) => idx !== imageIndex),
            imagePreviews: item.imagePreviews.filter((_, idx) => idx !== imageIndex)
          };
        }
        return item;
      })
    }));
  };

  const addItem = () => {
    setFormData(prev => ({
      ...prev,
      items: [...prev.items, { ...initialItemState }]
    }));
  };

  const removeItem = (index) => {
    if (formData.items.length > 1) {
      // Revoke all preview URLs for the item being removed
      formData.items[index].imagePreviews.forEach(url => URL.revokeObjectURL(url));
      
      setFormData(prev => ({
        ...prev,
        items: prev.items.filter((_, i) => i !== index)
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validateForm = () => {
    if (!formData.items.length) {
      setError('At least one item is required');
      return false;
    }
    if (!formData.items.every(item => item.name)) {
      setError('All items must have a name');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);
      const formDataToSend = new FormData();

      // Add items data
      formData.items.forEach((item, index) => {
        // Add item details
        const itemData = {
          name: item.name,
          description: item.description,
          quantity: item.quantity,
          specifications: item.specifications
        };
        formDataToSend.append(`items[${index}]`, JSON.stringify(itemData));
      
        // Add item images with a simpler field name
        item.images.forEach((image, imageIndex) => {
          formDataToSend.append(`items[${index}]image`, image);
        });
      });

      // Add other fields
      if (formData.budget) formDataToSend.append('budget', formData.budget);
      if (formData.phone) formDataToSend.append('phone', formData.phone);

      const response = await api.post('/api/service-requests', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Service request created:', response.data);
      setSuccess(true);

      // Clean up preview URLs
      formData.items.forEach(item => {
        item.imagePreviews.forEach(url => URL.revokeObjectURL(url));
      });

      setTimeout(() => {
        navigate('/service-requests/my-requests');
      }, 2000);
    } catch (error) {
      console.error('Error creating service request:', error);
      setError(error.response?.data?.message || 'Error creating service request');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Service Request
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        
        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Service request created successfully! Redirecting...
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {formData.items.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Paper sx={{ p: 2, bgcolor: 'grey.50' }}>
                  <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">
                      Item {index + 1}
                    </Typography>
                    {formData.items.length > 1 && (
                      <IconButton
                        onClick={() => removeItem(index)}
                        color="error"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Item Name"
                        value={item.name}
                        onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Quantity"
                        value={item.quantity}
                        onChange={(e) => handleItemChange(index, 'quantity', Math.max(1, parseInt(e.target.value) || 1))}
                        InputProps={{ inputProps: { min: 1 } }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        label="Item Description"
                        value={item.description}
                        onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        label="Specifications"
                        value={item.specifications}
                        onChange={(e) => handleItemChange(index, 'specifications', e.target.value)}
                        helperText="Optional: Add any specific requirements or specifications"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <input
                        accept="image/*"
                        type="file"
                        multiple
                        id={`image-input-${index}`}
                        style={{ display: 'none' }}
                        onChange={(e) => handleImageSelect(index, e)}
                      />
                      <label htmlFor={`image-input-${index}`}>
                        <Button
                          variant="outlined"
                          component="span"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload Images
                        </Button>
                      </label>

                      {/* Image Previews */}
                      <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {item.imagePreviews.map((preview, imageIndex) => (
                          <Card key={imageIndex} sx={{ width: 140, position: 'relative' }}>
                            <CardMedia
                              component="img"
                              height="140"
                              image={preview}
                              alt={`Preview ${imageIndex + 1}`}
                              sx={{ objectFit: 'cover' }}
                            />
                            <CardActions sx={{ p: 0, position: 'absolute', top: 0, right: 0 }}>
                              <IconButton
                                size="small"
                                onClick={() => handleDeleteImage(index, imageIndex)}
                                sx={{ 
                                  color: 'error.main',
                                  bgcolor: 'background.paper',
                                  '&:hover': { bgcolor: 'error.light', color: 'white' },
                                  m: 0.5
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </CardActions>
                          </Card>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}

            <Grid item xs={12}>
              <Button
                startIcon={<AddIcon />}
                onClick={addItem}
                variant="outlined"
                sx={{ mt: 1 }}
              >
                Add Another Item
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                name="budget"
                label="Budget (Optional)"
                value={formData.budget}
                onChange={handleChange}
                helperText="Estimated budget in GHS"
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="phone"
                label="Phone Number (Optional)"
                value={formData.phone}
                onChange={handleChange}
                helperText="Contact phone number"
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                <Button
                  variant="outlined"
                  onClick={() => navigate(-1)}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  startIcon={loading && <CircularProgress size={20} />}
                >
                  Submit Request
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default ServiceRequest; 