import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const Terms = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h2" component="h1" gutterBottom align="center">
        Terms & Conditions
      </Typography>

      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography paragraph>
            By accessing and using Provision Store's website and services, you
            accept and agree to be bound by these Terms and Conditions. If you do
            not agree to these terms, please do not use our services.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            2. Use of Service
          </Typography>
          <Typography paragraph>
            You agree to use our service for lawful purposes only and in a way
            that does not infringe upon the rights of others or restrict their use
            of the service.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            3. Account Registration
          </Typography>
          <Typography paragraph>
            To access certain features of our service, you may need to create an
            account. You are responsible for maintaining the confidentiality of
            your account information and for all activities under your account.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            4. Product Information
          </Typography>
          <Typography paragraph>
            We strive to provide accurate product information, but we do not
            warrant that product descriptions or other content is accurate,
            complete, or current. Prices and availability are subject to change
            without notice.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            5. Orders and Payment
          </Typography>
          <Typography paragraph>
            By placing an order, you agree to provide current, complete, and
            accurate purchase and account information. We reserve the right to
            refuse or cancel any order for any reason.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            6. Delivery
          </Typography>
          <Typography paragraph>
            Delivery times are estimates only. We are not responsible for delays
            beyond our control. Risk of loss and title for items pass to you upon
            delivery.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            7. Returns and Refunds
          </Typography>
          <Typography paragraph>
            We accept returns within 7 days of delivery for most items. Items must
            be unused and in original packaging. Perishable items cannot be
            returned.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            8. Intellectual Property
          </Typography>
          <Typography paragraph>
            All content on this website is the property of Provision Store and is
            protected by copyright and other intellectual property laws.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            9. Limitation of Liability
          </Typography>
          <Typography paragraph>
            We shall not be liable for any indirect, incidental, special,
            consequential, or punitive damages resulting from your use of our
            services.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h5" gutterBottom>
            10. Changes to Terms
          </Typography>
          <Typography paragraph>
            We reserve the right to modify these terms at any time. Changes will
            be effective immediately upon posting on the website. Your continued
            use of the service constitutes acceptance of the modified terms.
          </Typography>
        </Box>
      </Paper>

      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          Last updated: August 2023
        </Typography>
      </Box>
    </Container>
  );
};

export default Terms; 