import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Slider,
  Button,
  IconButton,
  useTheme,
  useMediaQuery,
  Pagination,
  Chip,
} from '@mui/material';
import {
  FilterList as FilterIcon,
  Close as CloseIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import ProductCard from '../components/ProductCard';
import LoadingSpinner from '../components/LoadingSpinner';
import api from '../utils/api';

const ProductList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  
  // Filter states
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [sortBy, setSortBy] = useState('featured');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const categories = [
    'Groceries',
    'Beverages',
    'Snacks',
    'Dairy',
    'Produce',
    'Household',
    'Kitchen',
    'Personal Care',
  ];

  useEffect(() => {
    // Get URL parameters
    const params = new URLSearchParams(location.search);
    const categoryParam = params.get('category');
    const searchParam = params.get('search');
    const sortParam = params.get('sort');
    const pageParam = params.get('page');

    // Set initial states from URL parameters
    if (categoryParam) {
      setSelectedCategories([categoryParam]);
    }
    if (searchParam) {
      setSearchQuery(searchParam);
    }
    if (sortParam) {
      setSortBy(sortParam);
    }
    if (pageParam) {
      setPage(Number(pageParam));
    }
  }, [location.search]);

  useEffect(() => {
    fetchProducts();
    // Update URL with current filters
    const params = new URLSearchParams();
    if (selectedCategories.length) {
      params.set('categories', selectedCategories.join(','));
    }
    if (searchQuery) {
      params.set('search', searchQuery);
    }
    if (sortBy !== 'featured') {
      params.set('sort', sortBy);
    }
    if (page !== 1) {
      params.set('page', page.toString());
    }
    if (priceRange[0] !== 0) {
      params.set('minPrice', priceRange[0].toString());
    }
    if (priceRange[1] !== 1000) {
      params.set('maxPrice', priceRange[1].toString());
    }

    const newUrl = `${location.pathname}${params.toString() ? `?${params.toString()}` : ''}`;
    navigate(newUrl, { replace: true });
  }, [selectedCategories, priceRange, sortBy, page, searchQuery]);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const params = new URLSearchParams({
        page: page.toString(),
        limit: '12',
        sortBy,
        ...(searchQuery && { search: searchQuery }),
        ...(selectedCategories.length && { categories: selectedCategories.join(',') }),
        ...(priceRange[0] !== 0 && { minPrice: priceRange[0].toString() }),
        ...(priceRange[1] !== 1000 && { maxPrice: priceRange[1].toString() }),
      });

      const response = await api.get(`/products?${params}`);
      setProducts(response.data.products);
      setTotalPages(Math.ceil(response.data.totalProducts / 12));
      setError(null);
    } catch (err) {
      console.error('Error fetching products:', err);
      setError('Failed to load products');
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
    setPage(1);
  };

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
    setPage(1);
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
    setPage(1);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  const clearFilters = () => {
    setSelectedCategories([]);
    setPriceRange([0, 1000]);
    setSortBy('featured');
    setSearchQuery('');
    setPage(1);
    navigate(location.pathname);
  };

  const Filters = () => (
    <Box sx={{ width: 250, p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Filters</Typography>
        {isMobile && (
          <IconButton onClick={() => setDrawerOpen(false)}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Categories
        </Typography>
        <List dense>
          {categories.map((category) => (
            <ListItem
              key={category}
              dense
              onClick={() => handleCategoryChange(category)}
              sx={{ cursor: 'pointer' }}
            >
              <Checkbox
                edge="start"
                checked={selectedCategories.includes(category)}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={category} />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Price Range
        </Typography>
        <Slider
          value={priceRange}
          onChange={handlePriceChange}
          valueLabelDisplay="auto"
          min={0}
          max={1000}
          step={10}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2">GHS {priceRange[0]}</Typography>
          <Typography variant="body2">GHS {priceRange[1]}</Typography>
        </Box>
      </Box>

      <Button
        variant="outlined"
        fullWidth
        onClick={clearFilters}
        sx={{ mt: 2 }}
      >
        Clear Filters
      </Button>
    </Box>
  );

  if (loading && !products.length) return <LoadingSpinner />;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ display: 'flex', gap: 3 }}>
        {/* Filters Drawer for Mobile */}
        {isMobile && (
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          >
            <Filters />
          </Drawer>
        )}

        {/* Filters Sidebar for Desktop */}
        {!isMobile && (
          <Box sx={{ width: 250, flexShrink: 0 }}>
            <Filters />
          </Box>
        )}

        {/* Main Content */}
        <Box sx={{ flexGrow: 1 }}>
          {/* Search and Sort Bar */}
          <Box sx={{ mb: 3 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <form onSubmit={handleSearch}>
                  <TextField
                    fullWidth
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search products..."
                    InputProps={{
                      endAdornment: (
                        <IconButton type="submit">
                          <SearchIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </form>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  {isMobile && (
                    <Button
                      startIcon={<FilterIcon />}
                      onClick={() => setDrawerOpen(true)}
                    >
                      Filters
                    </Button>
                  )}
                  <FormControl fullWidth size="small">
                    <InputLabel>Sort By</InputLabel>
                    <Select
                      value={sortBy}
                      onChange={handleSortChange}
                      label="Sort By"
                    >
                      <MenuItem value="featured">Featured</MenuItem>
                      <MenuItem value="priceAsc">Price: Low to High</MenuItem>
                      <MenuItem value="priceDesc">Price: High to Low</MenuItem>
                      <MenuItem value="newest">Newest</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Active Filters */}
          {(selectedCategories.length > 0 || searchQuery || sortBy !== 'featured' || 
            priceRange[0] !== 0 || priceRange[1] !== 1000) && (
            <Box sx={{ mb: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {selectedCategories.map((category) => (
                <Chip
                  key={category}
                  label={category}
                  onDelete={() => handleCategoryChange(category)}
                />
              ))}
              {searchQuery && (
                <Chip
                  label={`Search: ${searchQuery}`}
                  onDelete={() => setSearchQuery('')}
                />
              )}
              {(priceRange[0] !== 0 || priceRange[1] !== 1000) && (
                <Chip
                  label={`Price: GHS ${priceRange[0]} - GHS ${priceRange[1]}`}
                  onDelete={() => setPriceRange([0, 1000])}
                />
              )}
              {sortBy !== 'featured' && (
                <Chip
                  label={`Sort: ${sortBy}`}
                  onDelete={() => setSortBy('featured')}
                />
              )}
            </Box>
          )}

          {/* Products Grid */}
          {error ? (
            <Typography color="error" align="center">
              {error}
            </Typography>
          ) : (
            <>
              <Grid container spacing={3}>
                {products.map((product) => (
                  <Grid item xs={12} sm={6} md={4} key={product._id}>
                    <ProductCard product={product} />
                  </Grid>
                ))}
              </Grid>

              {/* Pagination */}
              {totalPages > 1 && (
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default ProductList; 